import { SaveOutlined } from '@ant-design/icons';
import {
    Button, Card, Col, Form, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useGeneralSettings } from '../hooks/useGeneralSettings';

function GeneralSettings() {
    const { form, loading, save } = useGeneralSettings();
    const { t } = useTranslation();

    return (
        <Card title={t('generalSettings')} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="cdek_phone"
                            label={t('cdekPhone')}
                        >
                            <Input type="text" />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    htmlType="submit"
                    type="primary"
                    icon={<SaveOutlined />}
                >
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default GeneralSettings;
