import { LoginOutlined } from '@ant-design/icons';
import {
    Button, Form, Input, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import AuthorizationContent from '../components/authorization-content';
import { getRequiredFieldMessage } from '../helpers/form';
import { useApp } from '../hooks/useApp';
import { useFetch } from '../hooks/useFetch';
import { useForm } from '../hooks/useForm';

interface ILoginResponse {
    token: string;
}

function LoginPage() {
    const app = useApp();
    const fetch = useFetch();
    const { t } = useTranslation();
    const [form] = useForm();

    const onFinish = async (data: any) => {
        const result = await fetch.request<ILoginResponse>({
            url: '/login',
            data,
            method: 'post',
        });
        if (result.success && result.data) {
            app.authSucceed(result.data.token);
        }
    };

    return (
        <AuthorizationContent title={t('panelTitle')}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label={t('loginField')}
                    name="login"
                    rules={[{
                        required: true,
                        message: getRequiredFieldMessage(t('login')),
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('password')}
                    name="password"
                    rules={[{
                        required: true,
                        message: getRequiredFieldMessage(t('password')),
                    }]}
                >
                    <Input.Password />
                </Form.Item>
                <Space className="w-100" direction="vertical">
                    <Button
                        className="w-100"
                        type="primary"
                        htmlType="submit"
                        icon={<LoginOutlined />}
                        size="large"
                        loading={fetch.loading}
                    >
                        {t('login')}
                    </Button>
                </Space>
            </Form>
        </AuthorizationContent>
    );
}

export default LoginPage;
