import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/table';
import { useGlsTransportationPrices } from '../../hooks/GLS/useGlsTransportationPrices';

function GlsTransportationPrices() {
    const { deleteRow, list } = useGlsTransportationPrices();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onEditClick = (id:number) => navigate(`/gls/transportation-price/${id}`);

    const onPlusClick = () => navigate('/gls/transportation-price/create');

    const actions = (_: any, row: any) => (
        <Space>
            <Button icon={<EditOutlined />} type="primary" onClick={() => onEditClick(row.id)} />
            <Button icon={<DeleteOutlined />} type="primary" onClick={() => deleteRow(row.id)} />
        </Space>
    );

    const extra = (
        <Button icon={<PlusOutlined />} type="primary" onClick={onPlusClick} />
    );

    return (
        <Card extra={extra} title={`GLS ${t('transportationPrices')}`}>
            <Table
                loading={list.loading}
                dataSource={list.data?.list}
                pagination={{
                    current: list.data?.currentPage,
                    total: list.data?.total,
                }}
                columns={[
                    {
                        dataIndex: 'country',
                        title: t('country'),
                    },
                    {
                        dataIndex: 'category',
                        title: t('category'),
                    },
                    {
                        dataIndex: 'price',
                        title: t('price'),
                    },
                    {
                        dataIndex: 'value',
                        title: t('value'),
                        render: (val) => (val === null ? '-' : val),
                    },
                    {
                        render: actions,
                        width: 100,
                    },
                ]}
            />
        </Card>
    );
}

export default GlsTransportationPrices;
