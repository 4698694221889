import {
    Button, Card, DatePicker, Form,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment';
import FileDownload from 'js-file-download';
import FetchService from '../../services/fetch';
import { useForm } from '../../hooks/useForm';
import { getRequiredFieldMessage } from '../../helpers/form';

function RusPostManifest() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const onSaveClick = async () => {
        const { range } = await form.validateFields();
        if (Array.isArray(range)) {
            if (range[0] instanceof moment && range[1] instanceof moment) {
                setLoading(true);
                const result = await FetchService.downloadFile({
                    url: '/ruspost/manifest',
                    method: 'POST',
                    data: { range },
                });
                if (result.success && result.data) {
                    FileDownload(result.data, `${t('manifest')}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                }
                setLoading(false);
            }
        }
    };

    return (
        <Card title={t('manifest')}>
            <Form form={form}>
                <Form.Item name="range" rules={[{ required: true, message: getRequiredFieldMessage() }]}>
                    <DatePicker.RangePicker format="DD.MM.YYYY" />
                </Form.Item>
                <Button
                    loading={loading}
                    icon={<SaveOutlined />}
                    type="primary"
                    onClick={onSaveClick}
                >
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default RusPostManifest;
