import { useEffect, useState } from 'react';
import { IListResponse } from '../types/list';
import { IWarehouseListItem } from '../types/warehouse';
import { useFetch } from './useFetch';

export const useWarehouses = () => {
    const fetch = useFetch();
    const [data, setData] = useState<IListResponse<IWarehouseListItem>>();

    const getData = async () => {
        const result = await fetch.request({
            url: '/warehouses',
            method: 'post',
            data: {
                count: 1000,
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    const getSelectOptions = () => {
        if (data instanceof Object) {
            return data.list.map((el) => ({
                value: el.id,
                label: el.country,
            }));
        }
        return [];
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        data,
        selectOptions: getSelectOptions(),
        loading: fetch.loading,
    };
};
