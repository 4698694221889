import { Button, Card } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientsList } from '../hooks/useClientsList';
import Table from '../components/table';

function ClientsPage() {
    const { data, loading } = useClientsList();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Card title={t('clientsList')}>
            <Table
                loading={loading}
                columns={[
                    {
                        dataIndex: 'id',
                        title: '#',
                    },
                    {
                        dataIndex: 'surname',
                        title: t('surname'),
                    },
                    {
                        dataIndex: 'name',
                        title: t('name'),
                    },
                    {
                        dataIndex: 'phone',
                        title: t('phone'),
                    },
                    {
                        dataIndex: 'active_orders_count',
                        title: t('activeOrdersCount'),
                    },
                    {
                        dataIndex: 'orders_count',
                        title: t('ordersCount'),
                    },
                    {
                        title: '',
                        key: 'action',
                        render: (_, record) => (
                            <Button icon={<EyeOutlined />} onClick={() => navigate(`/client/${record.id}`)} size="small" />
                        ),
                        fixed: 'right',
                        width: 55,
                    },
                ]}
                dataSource={data?.list}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                    pageSize: 30,
                }}
            />
        </Card>
    );
}

export default ClientsPage;
