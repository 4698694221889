import {
    Button, Dropdown, Layout, PageHeader, Menu,
} from 'antd';
import {
    UserOutlined, MenuOutlined, LogoutOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../hooks/useUser';
import RuPNG from '../assets/images/languages/russia.png';
import DePNG from '../assets/images/languages/germany.png';
import UkPNG from '../assets/images/languages/united-kingdom.png';

import '../styles/header.less';
import { useApp } from '../hooks/useApp';
import { useLanguage } from '../hooks/useLanguage';

interface IHeaderProps {
    onMenuClick: () => void;
    showMenuBtn: boolean;
}

export const languagesIcons = {
    'ru-RU': <img className="header-language-image" src={RuPNG} alt="Русский" />,
    'de-DE': <img className="header-language-image" src={DePNG} alt="Deutsch" />,
    'en-EN': <img className="header-language-image" src={UkPNG} alt="English" />,
};

function Header({ onMenuClick, showMenuBtn }: IHeaderProps) {
    const app = useApp();
    const { info: userInfo } = useUser();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { changeLanguage } = useLanguage();

    const onUserMenuClick = ({ key }: any) => {
        if (key === 'logout') {
            app.logout();
            return;
        }
        navigate(`/${key}`);
    };

    const userMenu = (
        <Menu
            onClick={onUserMenuClick}
            items={[{
                label: t('profile'),
                key: 'profile',
                icon: <UserOutlined />,
            }, {
                type: 'divider',
            }, {
                label: t('logout'),
                key: 'logout',
                icon: <LogoutOutlined />,
            }]}
        />
    );

    const languages = (
        <Menu
            style={{ width: 170 }}
            activeKey={i18n.language}
            items={[{
                label: 'Русский',
                key: 'ru-RU',
                icon: languagesIcons['ru-RU'],
            }, {
                label: 'Deutsch',
                key: 'de-DE',
                icon: languagesIcons['de-DE'],
            }, {
                label: 'English',
                key: 'en-EN',
                icon: languagesIcons['en-EN'],
            }]}
            onClick={({ key }) => changeLanguage(key)}
        />
    );

    const extra = (
        <>
            <Dropdown overlay={userMenu} placement="bottom">
                <Button className="row-items-centered" type="text" size="small">
                    <img className="header-user-dropdown-image" src={userInfo?.photo || 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'} alt="avatar" />
                    {!showMenuBtn && (
                        <>
                            {userInfo?.name}
                            {' '}
                            {userInfo?.surname}
                        </>
                    )}
                </Button>
            </Dropdown>
            <Dropdown overlay={languages} placement="bottomLeft">
                {languagesIcons[i18n.language as keyof typeof languagesIcons]}
            </Dropdown>
        </>
    );

    const title = (
        <Button hidden={!showMenuBtn} type="text" onClick={onMenuClick} size="small">
            <MenuOutlined />
        </Button>
    );

    return (
        <Layout.Header>
            <PageHeader extra={extra} title={title} />
        </Layout.Header>
    );
}

export default Header;
