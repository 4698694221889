import { useEffect, useState } from 'react';
import { ISelectOption } from '../types/general';
import { useFetch } from './useFetch';

export const usePromoCodeTypes = () => {
    const feth = useFetch();
    const [types, setTypes] = useState<ISelectOption[]>([]);

    const getTypes = async () => {
        const response = await feth.request({
            url: '/promo-codes/types',
            method: 'post',
        });
        if (response.success && Array.isArray(response.data)) {
            setTypes(response.data);
        }
    };

    useEffect(() => {
        getTypes();
    }, []);

    return {
        loading: feth.loading,
        types,
    };
};
