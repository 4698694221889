import { Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Table from '../../components/table';
import { useRusPostFulfillments } from '../../hooks/useRusPostFulfillments';
import { IFulfillment } from '../../types/rusPost';

function RusPostFulfillment() {
    const { deleteRow, loading, response } = useRusPostFulfillments();
    const { t } = useTranslation();

    const actions = (row: IFulfillment) => (
        <Space>
            <Link to={`/ruspost/fulfillment/${row.id}`}>
                <Button icon={<EditOutlined />} type="primary" />
            </Link>
            <Button icon={<DeleteOutlined />} type="primary" onClick={() => deleteRow(row.id)} />
        </Space>
    );

    const extra = (
        <Link to="/ruspost/fulfillment/create">
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('fulfillment')}>
            <Table
                columns={[{
                    dataIndex: 'type',
                    title: t('serviceLogsType'),
                }, {
                    dataIndex: 'price',
                    title: t('price'),
                }, {
                    dataIndex: 'weight',
                    title: t('orderPackageWeight'),
                }, {
                    align: 'center',
                    render: actions,
                    width: 100,
                }]}
                dataSource={response?.list}
                pagination={{
                    current: response?.currentPage,
                    total: response?.total,
                }}
                loading={loading}
            />
        </Card>
    );
}

export default RusPostFulfillment;
