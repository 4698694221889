import { SaveOutlined } from '@ant-design/icons';
import {
    Button,
    Card, Col, Form, Input, Row, Select, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RusPostTypePrices from '../../components/RusPost/type-prices';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useRusPostType } from '../../hooks/RusPost/useRusPostType';
import { useFetch } from '../../hooks/useFetch';
import { useOrderPackageUnits } from '../../hooks/useOrderPackageUnits';
import { ISelectOption } from '../../types/general';

function RusPostTypePage() {
    const {
        data, form, loading, save,
    } = useRusPostType();
    const { t } = useTranslation();
    const pu = useOrderPackageUnits();
    const companiesFetch = useFetch();
    const [companies, setCompanies] = useState<ISelectOption[]>([]);

    const getCompanies = async () => {
        const result = await companiesFetch.request<ISelectOption[]>({
            url: '/delivery/companies/select',
        });
        if (result.success && Array.isArray(result.data)) {
            setCompanies(result.data);
        }
    };

    const onSelect = (key: string, value: string | null) => form.setFieldValue(key, value);

    useEffect(() => {
        form.setFieldsValue({
            max_length_unit: data?.max_length_unit || pu.packageUnits?.linear[0].value,
            max_volume_unit: data?.max_volume_unit || pu.packageUnits?.linear[0].value,
        });
    }, [data, pu.packageUnits]);

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <Space className="w-100" direction="vertical">
            <Card title={t('deliveryTypes')} loading={typeof data === 'undefined' && loading}>
                <Form form={form} layout="vertical" onFinish={save}>
                    <Row gutter={10}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="delivery_company"
                                label={t('deliveryCompany')}
                                rules={[{ required: true, message: getRequiredFieldMessage(t('deliveryCompany')) }]}
                            >
                                <Select options={companies} />
                            </Form.Item>
                        </Col>
                        <Col md={16} />
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="de_name"
                                label={t('deName')}
                                rules={[{ required: true, message: getRequiredFieldMessage(t('deName')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="en_name"
                                label={t('enName')}
                                rules={[{ required: true, message: getRequiredFieldMessage(t('enName')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="ru_name"
                                label={t('ruName')}
                                rules={[{ required: true, message: getRequiredFieldMessage(t('ruName')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="code"
                                label={t('code')}
                                rules={[{ required: false, message: getRequiredFieldMessage('code') }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={16} />
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="de_delivery_time"
                                label={t('deDeliveryTime')}
                                rules={[{ required: false, message: getRequiredFieldMessage('deDeliveryTime') }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="en_delivery_time"
                                label={t('enDeliveryTime')}
                                rules={[{ required: false, message: getRequiredFieldMessage('enDeliveryTime') }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="ru_delivery_time"
                                label={t('ruDeliveryTime')}
                                rules={[{ required: false, message: getRequiredFieldMessage('ruDeliveryTime') }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="over_max_weight_price"
                                label={t('overMaxWeightPrice')}
                                rules={[{ required: false, message: getRequiredFieldMessage('overMaxWeightPrice') }]}
                            >
                                <Input addonAfter="€" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="max_length"
                                label={t('maxLength')}
                                rules={[{ required: false, message: getRequiredFieldMessage('maxLength') }]}
                            >
                                <Input
                                    type="number"
                                    addonAfter={(
                                        <Select
                                            key={`${pu.packageUnits?.linear[0].value}max_length_unit`}
                                            defaultValue={data?.max_length_unit || pu.packageUnits?.linear[0].value}
                                            loading={pu.loading}
                                            options={pu.packageUnits?.linear}
                                            onSelect={(val: any) => onSelect('max_length_unit', val)}
                                        />
                                    )}
                                />
                            </Form.Item>
                            <Form.Item name="max_length_unit" hidden />
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="over_max_length_price"
                                label={t('overMaxLengthPrice')}
                                rules={[{ required: false, message: getRequiredFieldMessage('overMaxLengthPrice') }]}
                            >
                                <Input addonAfter="€" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="max_volume"
                                label={t('maxVolume')}
                                rules={[{ required: false, message: getRequiredFieldMessage('maxVolume') }]}
                            >
                                <Input
                                    type="number"
                                    addonAfter={(
                                        <Select
                                            key={`${pu.packageUnits?.linear[0].value}max_volume_unit`}
                                            defaultValue={data?.max_volume_unit || pu.packageUnits?.linear[0].value}
                                            loading={pu.loading}
                                            options={pu.packageUnits?.linear}
                                            onSelect={(val: any) => onSelect('max_volume_unit', val)}
                                        />
                                    )}
                                />
                            </Form.Item>
                            <Form.Item name="max_volume_unit" hidden />
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="over_max_volume_price"
                                label={t('overMaxVolumePrice')}
                                rules={[{ required: false, message: getRequiredFieldMessage('overMaxVolumePrice') }]}
                            >
                                <Input addonAfter="€" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="additional_price"
                                label={t('additionalPrice')}
                                rules={[{ required: false, message: getRequiredFieldMessage('additionalPrice') }]}
                            >
                                <Input addonAfter="€" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="additional_price_per_kilogram"
                                label={t('additionalPricePerKilogram')}
                                rules={[{ required: false, message: getRequiredFieldMessage('additionalPricePerKilogram') }]}
                            >
                                <Input addonAfter="€" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="insurance_price"
                                label={t('insurancePrice')}
                                rules={[{ required: false, message: getRequiredFieldMessage('insurancePrice') }]}
                            >
                                <Input addonAfter="%" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button
                        icon={<SaveOutlined />}
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        {t('save')}

                    </Button>
                </Form>
            </Card>
            {typeof data?.id === 'number' && (
                <RusPostTypePrices />
            )}
        </Space>
    );
}

export default RusPostTypePage;
