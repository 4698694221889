import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { EnLanguage } from '../lang/en';
import { RuLanguage } from '../lang/ru';
import { DeLanguage } from '../lang/de';

const resources: Resource = {
    'de-DE': {
        translation: DeLanguage,
    },
    'en-EN': {
        translation: EnLanguage,
    },
    'ru-RU': {
        translation: RuLanguage,
    },
};

const getLangSearchParam = () => {
    const url = new URL(window.location.href.replace('#', ''));
    const lang = url.searchParams.get('lang');
    return Object.keys(resources).includes(lang as string) ? lang : null;
};

const getFallbackLng = () => getLangSearchParam() || 'en-EN';

const LanguageService = () => {
    i18next.use(initReactI18next);

    if (getLangSearchParam() === null) {
        i18next.use(LanguageDetector);
    }

    i18next.init({
        fallbackLng: getFallbackLng(),
        resources,
    });
};

export default LanguageService;
