import { SaveOutlined } from '@ant-design/icons';
import {
    Button,
    Card, Col, Form, Input, Row, Select,
} from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useRusPostTypePrice } from '../../hooks/RusPost/useRusPostTypePrice';
import { useOrderPackageUnits } from '../../hooks/useOrderPackageUnits';

function RusPostTypePricePage() {
    const { t } = useTranslation();
    const pu = useOrderPackageUnits();
    const {
        data, form, loading, save, typeId,
    } = useRusPostTypePrice();

    useEffect(() => {
        form.setFieldsValue({
            delivery_type_id: typeId,
            weight_unit: data?.weight_unit || pu.packageUnits?.weight[0].value,
        });
    }, [pu.packageUnits]);

    const onSelect = (key: string, value: string | null) => form.setFieldValue(key, value);

    return (
        <Card title={t('price')} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Form.Item name="delivery_type_id" hidden />
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('price')}
                            name="price"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('price')) }]}
                        >
                            <Input type="number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('orderPackageWeight')}
                            name="weight"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('weight')) }]}
                        >
                            <Input
                                type="number"
                                addonAfter={(
                                    <Select
                                        key={`${pu.packageUnits?.linear[0].value}weight_unit`}
                                        defaultValue={data?.weight_unit || pu.packageUnits?.weight[0].value}
                                        loading={pu.loading}
                                        options={pu.packageUnits?.weight}
                                        onSelect={(val: any) => onSelect('weight_unit', val)}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item name="weight_unit" hidden />
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default RusPostTypePricePage;
