import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from './useFetch';
import { IListResponse } from '../types/list';

interface IOrderChangeHistory {
    order_id: number;
    user_fio: string;
    created_at: string;
    description: string;
}

export const useOrderChangeHistory = () => {
    const fetch = useFetch();
    const [history, setHistory] = useState<IListResponse<IOrderChangeHistory>>();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');
    const { i18n } = useTranslation();

    const getLogs = async () => {
        let url = '/order-change-history';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await fetch.request({
            url,
            method: 'post',
            data: {
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success) {
            setHistory(result.data);
        }
    };

    useEffect(() => {
        getLogs();
    }, [page, i18n.language]);

    return {
        history,
        loading: fetch.loading,
    };
};
