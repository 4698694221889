import { notification } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FetchService, { TFetchServiceRequestConfig } from '../services/fetch';

interface IUseFetchOptions {
    showErrorMessage: boolean;
}

const defaultOptions: IUseFetchOptions = {
    showErrorMessage: true,
};

export const useFetch = ({ showErrorMessage } = defaultOptions) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    /**
     * Создать запрос на сервер
     * @param reqConfig - параметры запроса
     * @param silent - режим без идикатора загрузки
     * @returns Promise<IFetchServiceResponse<T>>
     */
    const request = async <T = any>(reqConfig: TFetchServiceRequestConfig, silent = false) => {
        if (!silent) {
            setLoading(true);
        }
        const result = await FetchService.makeRequest<T>(reqConfig);
        if (!result.success && showErrorMessage) {
            notification.error({
                message: result.message || t('httpRequestUnexpectedError'),
            });
        }
        if (!silent) {
            setLoading(false);
        }
        return result;
    };

    return {
        loading,
        request,
    };
};
