import { Input, Menu } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';

function SearchFilter({ confirm, setSelectedKeys }: FilterDropdownProps) {
    const onApplyClick = (search: string) => {
        if (typeof confirm === 'function') {
            setSelectedKeys(search.length > 0 ? [search] : []);
            confirm({ closeDropdown: true });
        }
        confirm();
    };
    return (
        <Menu className="ant-dropdown-menu">
            <Menu.Item>
                <Input.Search allowClear onSearch={onApplyClick} />
            </Menu.Item>
        </Menu>
    );
}

export default SearchFilter;
