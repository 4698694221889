import { useEffect } from 'react';
import {
    Avatar, Form, Input, Row, Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IUserInfo } from '../hooks/useUser';
import { useForm } from '../hooks/useForm';

interface IClientInfoProps {
    data?: IUserInfo;
}

function ClientInfo({ data }: IClientInfoProps) {
    const [form] = useForm();
    const { t } = useTranslation();

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    return (
        <Row gutter={[20, 20]}>
            <Col xs={24} md={6}>
                <div className="d-flex justify-content-center">
                    <Avatar
                        src={data?.photo}
                        size={120}
                    />
                </div>
            </Col>
            <Col xs={24} md={18}>
                <fieldset disabled>
                    <Form form={form} layout="vertical">
                        <Row gutter={10}>
                            <Col xs={24} md={12}>
                                <Form.Item label={t('surname')} name="surname">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={t('name')} name="name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={t('email')} name="email">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={t('phone')} name="phone">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={t('activeOrdersCount')} name="active_orders_count">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={t('ordersCount')} name="orders_count">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </fieldset>
            </Col>
        </Row>
    );
}

ClientInfo.defaultProps = {
    data: undefined,
};

export default ClientInfo;
