import React, {
    createContext, useMemo, useReducer,
} from 'react';
import { IListFilter } from '../types/list';

interface ITableContext {
    filters: IListFilter[];
}

interface ITablesContext {
    tables: Record<string, ITableContext>;
    functions: {
        saveFilters:(tableName: string, filters: IListFilter[]) => void;
    };
}

export const TablesContext = createContext<ITablesContext>({
    functions: {
        saveFilters: () => {},
    },
    tables: {},
});

interface IBaseAction<T, P> {
    payload: P;
    tableName: string;
    type: T;
}

interface ISetFiletersAction {
    filters: IListFilter[];
}

type TActions = IBaseAction<'filters', ISetFiletersAction>;

const reducer = (state: Record<string, ITableContext>, action: TActions) => {
    switch (action.type) {
        case 'filters': {
            if (typeof state[action.tableName] === 'undefined') {
                state[action.tableName] = {
                    filters: [],
                };
            }
            state[action.tableName].filters = action.payload.filters;
            return state;
        }
        default: {
            return state;
        }
    }
};

export function TablesContextWrapper({ children }: React.PropsWithChildren) {
    const [state, dispatch] = useReducer(reducer, {});

    const saveFilters = (tableName: string, filters: IListFilter[]) => {
        dispatch({
            payload: {
                filters,
            },
            tableName,
            type: 'filters',
        });
    };

    const value = useMemo(() => ({
        tables: state,
        functions: {
            saveFilters,
        },
    }), [state]);

    return (
        <TablesContext.Provider value={value}>
            {children}
        </TablesContext.Provider>
    );
}
