import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageForApi } from '../helpers/language';
import { useFetch } from './useFetch';
import { useUser } from './useUser';

export const useLanguage = (shouldFetch = false) => {
    const fetch = useFetch();
    const langFetch = useFetch();
    const [language, setLanguage] = useState<string>();
    const { i18n } = useTranslation();
    const { info } = useUser({ canFetch: false, canLogoutBy401: false, showFetchMessages: false });

    const changeLanguage = async (l: string) => {
        let url = `/language/${getLanguageForApi(l)}?redirect=false`;
        if (info instanceof Object) {
            url = `/user${url}`;
        }
        await langFetch.request({ url });
        i18n.changeLanguage(l);
    };

    const getLanguage = async () => {
        const result = await fetch.request<string>({
            url: '/language',
        });
        if (result.data) {
            setLanguage(result.data);
            i18n.changeLanguage(`${result.data}-${result.data.toUpperCase()}`);
        }
    };

    useEffect(() => {
        if (shouldFetch) {
            getLanguage();
        }
    }, [shouldFetch]);

    return {
        changeLanguage,
        changeLanguageLoading: langFetch.loading,
        language,
        languageLoading: fetch.loading,
    };
};
