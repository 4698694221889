import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { IGlsPriceListItem } from '../../types/gls';
import { IListResponse } from '../../types/list';
import { useFetch } from '../useFetch';

export const useGlsTransportationPrices = () => {
    const dataFetch = useFetch();
    const deleteFetch = useFetch();
    const [data, setData] = useState<IListResponse<IGlsPriceListItem>>();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const page = searchParams.get('page');

    const getData = async () => {
        let url = '/gls/transportation';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await dataFetch.request<IListResponse<IGlsPriceListItem>>({
            url,
            method: 'post',
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    const deleteRow = (id: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/gls/transportation/${id}/delete`,
                method: 'get',
            }).then(() => getData()),
            okText: t('delete'),
        });
    };

    useEffect(() => {
        getData();
    }, [page]);

    return {
        deleteRow,
        list: {
            data,
            loading: dataFetch.loading,
        },
    };
};
