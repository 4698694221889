import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClient } from '../hooks/useClient';
import ClientInfo from '../components/client-info';
import OrdersList from '../components/orders-list';

function ClientPage() {
    const { id } = useParams();
    const { data, loading } = useClient(typeof id === 'string' ? parseInt(id, 10) : undefined);
    const { t } = useTranslation();

    return (
        <>
            <Card title={`${t('clientInfo')}${id}`} loading={loading}>
                <ClientInfo data={data} />
            </Card>
            <Card
                title={data && `${t('clientOrdersList')} ${data?.surname} ${data?.name}`}
                loading={loading}
                style={{ marginTop: 20 }}
            >
                <OrdersList userId={typeof id === 'string' ? parseInt(id, 10) : undefined} />
            </Card>
        </>
    );
}

export default ClientPage;
