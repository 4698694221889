import { Translations } from '../types/translation';
import { ruTerms } from './ru-terms';

export const RuLanguage: Record<Translations, string> = {
    login: 'Войти',
    logout: 'Выйти',
    profile: 'Профиль',
    makeOrder: 'Оформить заказ',
    myOrders: 'Мои заказы',
    delete: 'Удалить',
    name: 'Имя',
    phone: 'Телефон',
    surname: 'Фамилия',
    transactions: 'Транзакции',
    uploadPhoto: 'Загрузить фото',
    userSettings: 'Настройки пользователя',
    email: 'Эл. почта',
    save: 'Сохранить',
    transactionsEmpty: 'Список транзакций пуст',
    addressSettings: 'Настройка адреса доставки',
    apartmentNumber: 'Номер квартиры/апартаментов',
    city: 'Город',
    country: 'Страна',
    district: 'Район',
    entrance: 'Подъезд',
    houseNumber: 'Дом',
    region: 'Регион',
    street: 'Улица',
    cancel: 'Отмена',
    userDeleteContent: 'После удаления, учетная запись станет недоступной',
    userDeleteTitle: 'Вы действительно хотите удалить учетную запись?',
    applicationCompleted: 'Оформление заявки завершено',
    deliveryDate: 'Дата доставки',
    direction: 'Направление',
    fromWhom: 'От кого',
    paid: 'Оплачен',
    sendTime: 'Время отправления',
    shippingCompany: 'Компания-перевозчик',
    status: 'Статус',
    toWhom: 'Кому',
    trackingNumber: 'Номер отслеживания',
    editOrder: 'Редактировать заказ',
    payOrder: 'Оплатить заказ',
    payOrderTitle: 'Оплата заказа №',
    currency: 'Валюта',
    sum: 'Сумма',
    pay: 'Оплатить',
    orderFrom: 'Откуда',
    orderPackageCost: 'Стоимость',
    orderPackageHeight: 'Высота',
    orderPackageItemsCount: 'Количество предметов',
    orderPackageLength: 'Длина',
    orderPackageName: 'Наименование',
    orderPackageWeight: 'Вес',
    orderPackageWidth: 'Ширина',
    orderPackageInformation: 'Информация о посылке',
    orderTo: 'Куда',
    back: 'Назад',
    next: 'Далее',
    update: 'Обновить',
    checkout: 'Оформление заказа',
    orderConfirmation: 'Подтверждение',
    orderFiller: 'Оформитель',
    orderFromTo: 'Откуда-Куда',
    orderRecipient: 'Получатель',
    orderSender: 'Отправитель',
    orderTransportation: 'Транспортировка',
    orderDeliveryToCompanyTypeTitle: 'Вариант передачи в Транспортную Компанию',
    orderFillerEmail: 'Эл. почта оформителя',
    orderPayer: 'Плательщик',
    orderMemberRecipientEmail: 'Эл. почта получателя',
    orderMemberRecipientFullAddress: 'Полный адрес получателя',
    orderMemberRecipientName: 'Имя получателя',
    orderMemberRecipientPhone: 'Телефон получателя',
    orderMemberRecipientPostalCode: 'Почтовый индекс получателя',
    orderMemberRecipientSurname: 'Фамилия получателя',
    orderMemberRecipientMiddlename: 'Отчество получателя',
    orderMemberRecipientCity: 'Город получателя',
    orderMemberRecipientStreet: 'Улица получателя',
    orderMemberRecipientHouse: 'Номер дома получателя',
    orderMemberRecipientBuilding: 'Номер строения получателя',
    orderMemberRecipientApartment: 'Номер квартиры/апартаментов получателя',
    orderMemberSenderEmail: 'Эл. почта отправителя',
    orderMemberSenderFullAddress: 'Полный адрес отправителя',
    orderMemberSenderName: 'Имя отправителя',
    orderMemberSenderPhone: 'Телефон отправителя',
    orderMemberSenderPostalCode: 'Почтовый индекс отправителя',
    orderMemberSenderSurname: 'Фамилия отправителя',
    orderMemberSenderMiddlename: 'Отчество отправителя',
    orderMemberSenderCity: 'Город отправителя',
    orderMemberSenderStreet: 'Улица отправителя',
    orderMemberSenderHouse: 'Номер дома отправителя',
    orderMemberSenderBuilding: 'Номер строения отправителя',
    orderMemberSenderApartment: 'Номер квартиры/апартаментов отправителя',
    orderMemberPassport: 'Паспорт',
    orderMemberPassportDate: 'Дата выдачи',
    orderMemberPassportDivisionCode: 'Код подразделения',
    orderMemberPassportIssuingAuthority: 'Кем выдан',
    orderMemberPassportNumber: 'Номер паспорта',
    orderMemberPassportSerialNumber: 'Серия паспорта',
    orderCancel: 'Отменить',
    orderConfirm: 'Подтвердить заказ',
    userInfoUpdatedMessage: 'Информация пользователя успешно обновлена',
    userPhotoUpdatedMessage: 'Фотография пользователя успешно обновлена',
    no: 'Нет',
    yes: 'Да',
    authorization: 'Авторизация',
    authorizationByEmail: 'по эл. почте',
    authorizationByPhone: 'по телефону',
    authorizationType: 'Тип авторизации',
    passwordForgot: 'Забыли пароль?',
    registration: 'Регистрация',
    password: 'Пароль',
    field: 'Поле',
    isMandatory: 'является обязательным',
    enteredIncorrectly: 'введено неверно',
    confirmPassword: 'Подтверждение пароля',
    register: 'Зарегистрироваться',
    passwordsDoNotMatch: 'Введенные пароли не совпадают',
    registrationRejected: 'Регистрация отклонена',
    registrationRejectedMessage: 'Вы не приняли условия пользования сервисом',
    serviceName: 'Телега',
    termsOfService: 'Условия пользования сервисом',
    passwordRestore: 'Восстановление пароля',
    passwordRestoreMessage:
        'Инструкции, по восстановлению пароля, были отправлены на вашу эл. почту:',
    restore: 'Восстановить',
    accept: 'Принять',
    welcome: 'Добро пожаловать',
    goToOrdersList: 'Перейти к списку заказов',
    orderPlacedSuccessfully: 'Заказ успешно размещен!',
    yourOrderNumber: 'Номер вашего заказа:',
    httpRequestUnexpectedError: 'Непредвиденная ошибка',
    httpRequestServeError: 'Ошибка загрузки данных',
    httpRequestUserNotAuthorized: 'Пользователь не авторизован',
    httpRequestUserNotFound: 'Запрашиваемый объект не найден',
    termsContent: ruTerms,
    panelTitle: 'Панель Администратора',
    loginField: 'Логин',
    activeOrdersCount: 'Кол-во активных заказов',
    ordersCount: 'Всего заказов',
    transactionType: 'Тип транзакции',
    date: 'Дата',
    clients: 'Клиенты',
    finances: 'Финансы',
    ordersInfo: 'Информация об отправлениях',
    serviceLogs: 'Логи',
    clientInfo: 'Информация о клиенте №',
    clientOrdersList: 'Список заказов клиента:',
    clientsList: 'Список клиентов',
    transactionInfo: 'Информация о транзакции №',
    financialBalance: 'Финансовый баланс:',
    transactionPayer: 'Плательщик',
    serviceLogsType: 'Тип операции',
    time: 'Время',
    user: 'Пользователь',
    exportToExcel: 'Экспортировать в Excel',
    orderInfo: 'Информация о заказе №',
    fileDownloadedSuccessfully: 'Файл успешно скачан',
    fileDownloadError: 'Ошибка скачивания файла',
    orderCreatedDate: 'Дата оформления заказа',
    serviceCommissions: 'Комиссия сервиса',
    deleteConfirmation: 'Вы действительно хотите удалить выбранную запись?',
    confirmAction: 'Подтвердите действие',
    logicalOperation: 'Логическая операция',
    prices: 'Цены',
    price: 'Цена',
    rusPostCodes: 'Трек-номера',
    deliveryType: 'Тип доставки',
    deliveryTypes: 'Типы доставок',
    category: 'Категория',
    value: 'Значение',
    deliveryTime: 'Сроки доставки',
    code: 'Код',
    maxLength: 'Максимальный размер',
    maxVolume: 'Максимальный объем',
    overMaxLengthPrice: 'Цена сверх максимального размера',
    overMaxVolumePrice: 'Цена сверх максимального объема',
    overMaxWeightPrice: 'Цена сверх максимального веса',
    deDeliveryTime: 'Сроки доставки (Немецкий язык)',
    deName: 'Имя (Немецкий язык)',
    enDeliveryTime: 'Сроки доставки (Английский язык)',
    enName: 'Имя (Английский язык)',
    ruDeliveryTime: 'Сроки доставки (Русский язык)',
    ruName: 'Имя (Русский язык)',
    countries: 'Страны',
    transportation: 'Транспортировка',
    transportationPrice: 'Цена транспортировки',
    transportationPrices: 'Цены транспортировки',
    orderNumber: 'Номер заказа',
    importSucceed: 'Импортирование успшено завершено',
    trackingNumberSelectedAt: 'Привязан к заказу',
    system: 'Система',
    additionalPrice: 'Надбавочная стоимость',
    additionalPricePerKilogram: 'Надбавочная стоимость за каждый килограмм',
    placeholderSearch: 'Поиск',
    deliveryCompany: 'Компания доставки',
    hidden: 'Скрыто',
    commissionValue: 'Размер комиссии',
    deliveryTypeCommission: 'Комиссия типа доставки',
    customsDuties: 'Таможенные пошлины',
    generalSettings: 'Общие настройки',
    cdekPhone: 'Телефон для СДЭК',
    orderQrInfo:
        'Пожалуйста, сохраните данный код на ваше устройство. Запакуйте вашу посылку и отнесите ее в ближайшее отделение GLS в вашем городе. На месте предъявите данный код, сотрудник отделения отсканирует его и наклеит адресный ярлык на вашу посылку.',
    download: 'Скачать',
    manifest: 'Манифест',
    parcelInsurance: 'Страховка посылки',
    insurancePrice: 'Стоимость страховки',
    warehouses: 'Склады',
    address: 'Адрес',
    companyName: 'Название фирмы',
    postalCode: 'Почтовый индекс',
    workMode: 'Режим работы',
    deWorkMode: 'Режим работы (Немецкий язык)',
    enWorkMode: 'Режим работы (Английский язык)',
    ruWorkMode: 'Режим работы (Русский язык)',
    orderType: 'Тип заказа',
    promoCodes: 'Промокоды',
    promoCode: 'Промокод',
    amountOfDiscount: 'Размер скидки',
    description: 'Описание',
    promoCodeExpiresAt: 'Действителен до',
    promoCodeStartsAt: 'Действителен от',
    fulfillment: 'Фуллфилмент',
    purchaseName: 'Покупка',
    shopName: 'Название магазина',
    warehouse: 'Склад',
    predefinedAddresses: 'Предопределенные адреса',
    predefinedAddress: 'Предопределенный адрес',
    representative: 'Имя представителя',
    house: 'Номер дома',
    building: 'Номер строения',
    apartment: 'Номер квартиры/апартаментов',
    perItem: 'за единицу товара',
    goodName: 'Наименование товара',
    goods: 'Товар',
    orderPackageItemUrl: 'Ссылка на товар',
    purchases: 'Покупки',
    productAdding: 'Добавление товара',
    goodsList: 'Список товаров',
    expressShipment: 'Экспресс-доставка',
    regularShipment: 'Обычная доставка',
    itemTotalCost: 'Общая стоимость данной позиции',
    maxWeight: 'Максимальный вес',
    maxGirth: 'Максимальный обхват',
    parcelDimensionsAttentionInformation: 'Просим вас вводить габбариты и вес корректно! В случае расхождения заявленных и фактических данных, стоимость услуги будет откорректирована.',
    sanctionedGoodsInfo: 'Убедительно просим Вас ознакомиться со списком санкционных товаров, запрещенных к ввозу в Российскую Федерацию',
    client: 'Клиент',
    promoCodeType: 'Тип промокода',
    china: 'Китай',
    orderPackageCode: 'Код товара',
    orderPackageRuName: 'Содержимое посылки (Русский язык)',
    purchaseAdding: 'Добавление покупки',
    addPurchase: 'Добавить покупку',
    paidDataUpdated: 'Данные заказа обновлены. Уведомить сторонние сервисы? (RusPost, RusPostWMS)',
    notify: 'Уведомить',
    makeOrderPaid: 'Провести как оплаченный',
    changelogs: 'Истории изменений',
};
