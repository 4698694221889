import { useEffect, useState } from 'react';
import { ISelectOption } from '../types/general';
import { useFetch } from './useFetch';

export const useLogicalOperations = () => {
    const fetch = useFetch();
    const [logicalOperations, setLogicalOperations] = useState<ISelectOption[]>([]);

    const getLogicalOperations = async () => {
        const result = await fetch.request<ISelectOption[]>({
            url: '/service-commissions/logical-operations',
            method: 'get',
        });
        if (result.success && result.data) {
            setLogicalOperations(result.data);
        }
    };

    useEffect(() => {
        getLogicalOperations();
    }, []);

    return {
        data: logicalOperations,
        loading: fetch.loading,
    };
};
