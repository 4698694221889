import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IListResponse } from '../types/list';
import { useFetch } from './useFetch';
import { IWarehouse } from '../types/warehouse';

export const useWarehousesList = () => {
    const fetch = useFetch();
    const deleteFetch = useFetch();
    const [data, setData] = useState<IListResponse<IWarehouse>>();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const page = searchParams.get('page');

    const deleteRow = (x: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/warehouses/${x}/delete`,
                method: 'get',
            }).then(() => getData()),
            okText: t('delete'),
        });
    };

    const getData = async () => {
        const result = await fetch.request({
            url: `/warehouses?page=${page || 1}`,
            method: 'post',
            data: {
                count: 10,
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useEffect(() => {
        getData();
    }, [page]);

    return {
        data,
        loading: fetch.loading,
        deleteRow,
    };
};
