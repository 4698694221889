import { t } from 'i18next';
import { Metadata, parsePhoneNumber } from 'libphonenumber-js';

export const getRequiredFieldMessage = (fieldName?: any) => (typeof fieldName === 'string' ? `${t('field')} ${t('isMandatory')}` : `${t('field')} "${fieldName}" ${t('isMandatory')}`);

export const getEnteredIncorrectlyFieldMessage = (fieldName: string) => `${t('field')} "${fieldName}" ${t('enteredIncorrectly')}`;

// @ts-ignore
export const getPhoneCountryCodes = () => Object.keys((new Metadata()).metadata.country_calling_codes);

export const getPhoneCountryCode = (phone: string) => {
    try {
        return `+${parsePhoneNumber(phone).countryCallingCode}`;
    } catch (err) {
        return '';
    }
};

// @ts-ignore
export const getCountryCodeFromPhoneCode = (phoneCode: string): string | undefined => (new Metadata()).metadata.country_calling_codes[phoneCode]?.[0];
