import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import produce from 'immer';
import { IPurchase, IPurchaseItem } from '../types/purchase';
import { useFetch } from './useFetch';
import { useForm } from './useForm';

export const usePurchaseItem = (inModal = false, purchase?: IPurchase, purchaseItem?: IPurchaseItem) => {
    const [formData, setFormData] = useState<IPurchaseItem>();
    const formDataFetch = useFetch();
    const saveFetch = useFetch();
    const navigate = useNavigate();
    const [form] = useForm();

    const newRow = !(purchaseItem instanceof Object);

    const getFormData = async () => {
        if (!newRow) {
            const result = await formDataFetch.request({
                url: `/purchase-items/${purchaseItem.id}`,
                method: 'get',
            });
            if (result.success) {
                setFormData(result.data);
            }
        } else {
            setFormData(undefined);
        }
    };

    const save = async (data: IPurchaseItem, callback?: () => void) => {
        const result = await saveFetch.request<IPurchaseItem>({
            url: `/purchase-items/${newRow ? 'create' : `${purchaseItem.id}/update`}`,
            method: 'post',
            data: produce(data, (drfat) => {
                if (purchase instanceof Object) {
                    drfat.purchase_id = purchase.id;
                }
            }),
        });
        if (result.success) {
            if (!inModal && purchase) {
                navigate(`/purchases/${purchase.id}/items`);
            }
            notification.success({
                message: result.message,
            });
            if (typeof callback === 'function') {
                callback();
            }
        }
    };

    useEffect(() => {
        getFormData();
    }, [purchaseItem?.id]);

    useEffect(() => {
        form.setFieldsValue({
            ...formData,
        });
    }, [formData]);

    return {
        form,
        formData,
        formDataLoading: formDataFetch.loading,
        save,
        saveLoading: saveFetch.loading,
    };
};
