import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../useFetch';
import { useForm } from '../useForm';

interface IRusPostType {
    id: number;
    name: string;
    code: string;
    de_delivery_time: string;
    en_delivery_time: string;
    ru_delivery_time: string;
    over_max_weight_price: number;
    max_length: number;
    max_length_unit: string;
    over_max_length_price: number;
    max_volume: number;
    max_volume_unit: string;
    over_max_volume_price: number;
}

export const useRusPostType = () => {
    const fetch = useFetch();
    const [form] = useForm();
    const [data, setData] = useState<IRusPostType>();
    const { id } = useParams();
    const navigate = useNavigate();

    const newRow = id === 'create';

    const getData = async () => {
        if (!newRow) {
            const result = await fetch.request<IRusPostType>({
                url: `/ruspost/types/${id}`,
            });
            if (result.success && result.data) {
                setData(result.data);
            }
        }
    };

    const save = async (values: any) => {
        const result = await fetch.request({
            url: newRow ? '/ruspost/types/create' : `/ruspost/types/${id}/update`,
            method: 'post',
            data: values,
        });
        if (result.success && result.data) {
            if (newRow) {
                navigate(`/delivery/type/${result.data.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (newRow) {
            setData(undefined);
            form.resetFields();
        } else {
            getData();
        }
    }, [id]);

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    return {
        data,
        form,
        loading: fetch.loading,
        save,
    };
};
