import {
    Avatar, Card, Row, Col, Space, Typography, Upload, Button, Form, Input, Modal,
} from 'antd';
import {
    UploadOutlined, LogoutOutlined, SaveOutlined, DeleteOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import { useUser } from '../hooks/useUser';
import { getRequiredFieldMessage } from '../helpers/form';
import { useApp } from '../hooks/useApp';
import { useForm } from '../hooks/useForm';

const { confirm } = Modal;

function ProfilePage() {
    const app = useApp();
    const { info, update, uploadPhoto } = useUser();
    const { t } = useTranslation();
    const [form] = useForm();

    const onDeleteClick = () => {
        confirm({
            title: t('userDeleteTitle'),
            icon: <ExclamationCircleOutlined />,
            content: t('userDeleteContent'),
            cancelText: t('cancel'),
            okText: t('delete'),
            onOk: onLogout,
        });
    };

    const onFinish = (values: any) => {
        update.request(values);
    };

    const onLogout = app.logout;

    const onUploadChange = async (e: UploadChangeParam<UploadFile<any>>) => {
        if (e.event?.percent === 100) {
            uploadPhoto.request(e.file.originFileObj as Blob);
        }
    };

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24} lg={8}>
                <Card style={{ display: 'flex', justifyContent: 'center' }}>
                    <Space align="center" direction="vertical" size="middle">
                        <Avatar
                            src={info?.photo}
                            size={120}
                        />
                        <Typography.Title level={3} className="mb-0">
                            {info?.name}
                            {' '}
                            {info?.surname}
                        </Typography.Title>
                        <Upload showUploadList={false} onChange={onUploadChange}>
                            <Button icon={<UploadOutlined />} loading={uploadPhoto.loading}>
                                {t('uploadPhoto')}
                            </Button>
                        </Upload>
                        <Space direction="horizontal">
                            <Button danger icon={<DeleteOutlined />} onClick={onDeleteClick}>
                                {t('delete')}
                            </Button>
                            <Button icon={<LogoutOutlined />} onClick={onLogout}>
                                {t('logout')}
                            </Button>
                        </Space>
                    </Space>
                </Card>
            </Col>
            {info && (
                <Col xs={24} lg={16}>
                    <Card title={t('userSettings')}>
                        <Form form={form} initialValues={info} layout="vertical" onFinish={onFinish}>
                            <Row gutter={10}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={t('password')}
                                        name="password"
                                        rules={[{ required: true, message: getRequiredFieldMessage(t('password')) }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={t('confirmPassword')}
                                        name="confirmPassword"
                                        rules={[
                                            { required: true, message: getRequiredFieldMessage(t('confirmPassword')) },
                                            ({ getFieldValue }) => ({
                                                validator: (_, value) => {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t('passwordsDoNotMatch') as string));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit" loading={update.loading}>
                                {t('save')}
                            </Button>
                        </Form>
                    </Card>
                </Col>
            )}
        </Row>
    );
}

export default ProfilePage;
