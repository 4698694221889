import { Button, Card, Space } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Table from '../../components/table';
import { usePromoCodes } from '../../hooks/usePromoCodes';
import { IPromoCode } from '../../types/promo-code';

function PromoCodesListPage() {
    const { deleteRow, response, loading } = usePromoCodes();
    const { t } = useTranslation();

    const actions = (row: IPromoCode) => (
        <Space>
            <Link to={`/promo-codes/${row.id}`}>
                <Button
                    icon={<EditOutlined />}
                    type="primary"
                />
            </Link>
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    const extra = (
        <Link to="/promo-codes/create">
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('promoCodes')}>
            <Table
                loading={loading}
                columns={[{
                    dataIndex: 'id',
                    title: '№',
                    align: 'center',
                }, {
                    dataIndex: 'code',
                    title: t('promoCode'),
                    align: 'center',
                }, {
                    dataIndex: 'amount_of_discount',
                    title: t('amountOfDiscount'),
                    render: (val) => `${val}%`,
                    align: 'center',
                }, {
                    dataIndex: 'description',
                    title: t('description'),
                    align: 'center',
                }, {
                    dataIndex: 'type',
                    title: t('promoCodeType'),
                    align: 'center',
                }, {
                    dataIndex: 'starts_at',
                    title: t('promoCodeStartsAt'),
                    align: 'center',
                }, {
                    dataIndex: 'expires_at',
                    title: t('promoCodeExpiresAt'),
                    align: 'center',
                }, {
                    render: actions,
                    width: 100,
                }]}
                dataSource={response?.list}
                pagination={{
                    current: response?.currentPage,
                    total: response?.total,
                }}
            />
        </Card>
    );
}

export default PromoCodesListPage;
