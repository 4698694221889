import {
    Button, Col, Form, Row, Select, Space,
} from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useDeliveryTypes } from '../../hooks/useDeliveryTypes';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useOrder } from '../../hooks/useOrder';
import { IOrder } from '../../types/order';
import { IMakeOrderStepsProps } from './first';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

function MakeOrderDeliveryStep({ onBackPress, goNextStep, canMoveBetweenSteps }: IMakeOrderStepsProps) {
    const saveFetch = useFetch();
    const order = useOrder();
    const { i18n, t } = useTranslation();
    const [form] = useForm();
    const dt = useDeliveryTypes(order.data?.id);
    const { getFieldMandatory, selectOpen, watchMode } = useFormWatchMode();

    useEffect(() => {
        if (order.data) {
            dt.getData();
            form.setFieldsValue(order.data);
        }
    }, [order.data, i18n.language]);

    const onFinish = async (values: Record<string, any>) => {
        const result = await saveFetch.request<IOrder>({
            url: `/orders/${order.data?.id}/update`,
            data: values,
            method: 'post',
        });
        if (result.success) {
            await order.update(result.data?.id);
            if (canMoveBetweenSteps) {
                goNextStep();
            }
        }
    };

    const onSelectFilterOption = (input: string, option?: BaseOptionType) => (option?.label?.toString().toLocaleLowerCase() ?? '').includes(input.toLowerCase());

    return (
        <Form form={form} initialValues={order.data} layout="vertical" onFinish={onFinish}>
            <Row gutter={[30, 30]}>
                <Col xs={24} md={8}>
                    <Form.Item
                        label={t('deliveryType')}
                        name="delivery_type_id"
                        rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('deliveryType')) }]}
                    >
                        <Select
                            open={selectOpen}
                            options={dt.data}
                            loading={dt.loading}
                            filterOption={onSelectFilterOption}
                        />
                    </Form.Item>
                </Col>
                <Col className="text-center" xs={24}>
                    <Space className="form-action-buttons" direction="horizontal" hidden={watchMode} size="large">
                        {canMoveBetweenSteps && (
                            <Button type="link" onClick={onBackPress}>
                                {t('back')}
                            </Button>
                        )}
                        <Button type="primary" htmlType="submit" loading={saveFetch.loading || order.dataLoading}>
                            {canMoveBetweenSteps ? t('next') : t('update')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
}

export default MakeOrderDeliveryStep;
