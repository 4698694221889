import {
    Button, Card, Form, Input, Select, Row, Col, Space,
} from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useGlsCountry } from '../../hooks/GLS/useGlsCountry';
import { useCountries } from '../../hooks/useCountries';

function GlsCountryFormPage() {
    const { countries } = useCountries();
    const { form, loading, save } = useGlsCountry();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const countryCode = searchParams.get('countryCode');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (typeof countryCode === 'string') {
            form.setFieldValue('country_code', countryCode);
        }
    }, [countryCode]);

    const onBackClick = () => {
        navigate(`/gls/countries/country/${form.getFieldValue('country_code')}`);
    };

    return (
        <Card title={`GLS ${t('price')}`} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="country_code"
                            required
                            label={t('country')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('country')) }]}
                        >
                            <Select
                                open={typeof countryCode === 'string' ? false : undefined}
                                filterOption={(input, option) => (option?.label as any ?? '').includes(input)}
                                showSearch
                                options={countries.data}
                                loading={countries.loading}

                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="price"
                            required
                            label={t('price')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('price')) }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="weight"
                            required
                            label={t('orderPackageWeight')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    <Button htmlType="submit" type="primary">
                        {t('save')}
                    </Button>
                    {(typeof countryCode === 'string' || (typeof id === 'string' && id !== 'create')) && (
                        <Button type="default" onClick={onBackClick}>
                            {t('back')}
                        </Button>
                    )}
                </Space>
            </Form>
        </Card>
    );
}

export default GlsCountryFormPage;
