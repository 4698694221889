import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../useFetch';

export const useGlsTransportationPrice = () => {
    const fetch = useFetch();
    const [form] = useForm();
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<Record<string, any>>({});

    const newRow = id === 'create';

    const getData = async () => {
        const result = await fetch.request({
            url: `/gls/transportation/${id}`,
            method: 'get',
        });
        if (result.success && result.data) {
            setFormData(result.data);
        }
    };

    const save = async (data: Record<string, any>) => {
        const result = await fetch.request({
            url: newRow ? '/gls/transportation/create' : `/gls/transportation/${id}/update`,
            method: 'post',
            data,
        });
        if (result.success && result.data) {
            if (newRow) {
                navigate(`/gls/transportation-price/${result.data.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
        }
    }, [id]);

    useEffect(() => {
        form.setFieldsValue(formData);
    }, [formData]);

    return {
        form,
        formData,
        loading: fetch.loading,
        save,
    };
};
