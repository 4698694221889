import React from 'react';
import {
    Button, Card, Typography, Divider, Space,
} from 'antd';
import { EyeOutlined, RightCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useServiceBalance } from '../hooks/useServiceBalance';
import { useTransactions } from '../hooks/useTransactions';
import { ITransaction } from '../types/transaction';
import Table from '../components/table';

function FinancesPage() {
    const { data, loading } = useTransactions();
    const { balance } = useServiceBalance();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const actions = (_:any, row:ITransaction) => (
        <Space>
            <Button
                icon={<EyeOutlined />}
                onClick={() => navigate(`/finance/${row.id}`)}
                size="small"
            />
            <Button
                type="primary"
                icon={<RightCircleOutlined />}
                onClick={() => navigate(`/order/${row.order_id}`)}
                size="small"
            />
        </Space>
    );

    return (
        <Card title={(
            <Typography.Title className="mb-0" level={5}>
                {t('financialBalance')}
                {React.Children.toArray(balance?.map((item, index) => (
                    <>
                        <Divider type="vertical" style={{ opacity: index === 0 ? 0 : 1, borderColor: 'black' }} />
                        <b>{item.value}</b>
                    </>
                )))}
            </Typography.Title>
        )}
        >
            <Table
                loading={loading}
                columns={[
                    {
                        dataIndex: 'id',
                        title: '#',
                    },
                    {
                        dataIndex: 'typeLabel',
                        title: t('transactionType'),
                    },
                    {
                        dataIndex: 'value',
                        title: t('sum'),
                        render: (_, row: ITransaction) => `${row.value} ${row.currencySuffix}`,
                    },
                    {
                        dataIndex: 'created_at',
                        title: t('date'),
                        render: (_, row: ITransaction) => moment(row.created_at).format('DD.MM.YYYY HH:mm'),
                    },
                    {
                        dataIndex: 'user',
                        title: t('transactionPayer'),
                        render: (_, row: ITransaction) => (row.user ? `${row.user.name} ${row.user.surname}` : t('system')),
                    },
                    {
                        dataIndex: 'tracking_code',
                        title: t('trackingNumber'),
                    },
                    {
                        dataIndex: 'tracking_code_ruspost',
                        title: `${t('trackingNumber')} (RusPost)`,
                    },
                    {
                        title: '',
                        key: 'action',
                        render: actions,
                        fixed: 'right',
                        width: 55,
                    },
                ]}
                dataSource={data?.list}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                }}
            />
        </Card>
    );
}

export default FinancesPage;
