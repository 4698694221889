import { useForm as antdUseForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useForm = () => {
    const [form] = antdUseForm();
    const { i18n } = useTranslation();

    const processFieldsError = () => {
        const fields = form.getFieldsError();
        const names = fields.filter((el) => el.errors.length > 0).map((el) => el.name[0]);
        form.validateFields(names);
    };

    useEffect(() => {
        processFieldsError();
    }, [i18n.language]);

    return [form];
};
