import { useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { IOrdersListFilters } from '../types/order';

export const useOrdersListFilters = () => {
    const fetch = useFetch();
    const [filters, setFilters] = useState<IOrdersListFilters>();

    const getFilters = async () => {
        const response = await fetch.request<IOrdersListFilters>({ url: '/orders/filters' });

        if (response.success && response.data instanceof Object) {
            setFilters(response.data);
        }
    };

    useEffect(() => {
        getFilters();
    }, []);

    return filters;
};
