import {
    Button,
    Card, Col, Form, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import { getRequiredFieldMessage } from '../../helpers/form';
import { usePredefinedOrderMember } from '../../hooks/usePredefinedOrderMember';
import { useCountries } from '../../hooks/useCountries';

function PredefinedOrderMemberFormPage() {
    const { countries } = useCountries();
    const {
        dataLoading, form, loading, save,
    } = usePredefinedOrderMember();
    const { t } = useTranslation();

    return (
        <Card title={t('predefinedAddress')} loading={dataLoading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="country_code"
                            label={t('country')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('country')) }]}
                        >
                            <Select
                                filterOption={(input, option) => (option?.label as any ?? '').includes(input)}
                                showSearch
                                options={countries.data}
                                loading={countries.loading}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="region"
                            label={t('region')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="district"
                            label={t('district')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="city"
                            label={t('city')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="street"
                            label={t('street')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="house"
                            label={t('house')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="building"
                            label={t('building')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="apartment"
                            label={t('apartment')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={10}>
                            <Col xs={24} md={8} xl={6}>
                                <Form.Item
                                    name="representative"
                                    label={t('representative')}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8} xl={6}>
                                <Form.Item
                                    name="phone"
                                    label={t('phone')}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8} xl={6}>
                                <Form.Item
                                    name="email"
                                    label={t('email')}
                                >
                                    <Input type="email" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8} xl={6}>
                                <Form.Item
                                    name="postal_code"
                                    label={t('postalCode')}
                                >
                                    <Input type="postal_code" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SaveOutlined />}
                        loading={loading}
                    >
                        {t('save')}
                    </Button>
                </Row>
            </Form>
        </Card>
    );
}

export default PredefinedOrderMemberFormPage;
