import { useEffect, useState } from 'react';
import { ITransaction } from '../types/transaction';
import { useFetch } from './useFetch';

export const useTransaction = (transactionId?: number) => {
    const fetch = useFetch();
    const [data, setData] = useState<ITransaction>();

    const getData = async (id: number) => {
        const result = await fetch.request({
            url: `/transactions/${id}`,
        });
        if (result.success) {
            setData(result.data);
        }
    };

    useEffect(() => {
        if (typeof transactionId === 'number') {
            getData(transactionId);
        }
    }, [transactionId]);

    return {
        data,
        loading: fetch.loading,
    };
};
