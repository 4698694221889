import { useEffect } from 'react';
import {
    Button, Form, Input, Row, Col,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RightCircleOutlined } from '@ant-design/icons';
import { ITransaction } from '../types/transaction';
import { useForm } from '../hooks/useForm';

interface IFinanceInfoProps {
    data?: ITransaction;
}

function FinanceInfo({ data }: IFinanceInfoProps) {
    const [form] = useForm();
    const { t } = useTranslation();

    useEffect(() => {
        form.setFieldsValue({
            ...data,
            userName: `${data?.user?.surname} ${data?.user?.name}`,
            created_at: moment(data?.created_at).format('DD.MM.YYYY HH:mm'),
        });
    }, [data]);

    return (
        <fieldset disabled>
            <Form form={form} layout="vertical">
                <Row gutter={10}>
                    <Col xs={24} md={12}>
                        <Form.Item label={t('transactionType')} name="typeLabel">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={t('orderPayer')} name="userName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={t('sum')} name="value">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={t('currency')} name="currencyLabel">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={t('date')} name="created_at">
                            <Input />
                        </Form.Item>
                    </Col>
                    {typeof data?.order_id === 'number' && (
                        <Col xs={24}>
                            <Button
                                icon={<RightCircleOutlined />}
                                type="primary"
                                href={`#/order/${data.order_id}`}
                            >
                                {t('orderInfo') + data.order_id}
                            </Button>
                        </Col>
                    )}
                </Row>
            </Form>
        </fieldset>
    );
}

FinanceInfo.defaultProps = {
    data: undefined,
};

export default FinanceInfo;
