/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import {
    Button, Col, Form, Input, Row, Select, Space, Spin, Typography, Grid,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseOptionType } from 'antd/lib/select';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useCountries } from '../../hooks/useCountries';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useOrder } from '../../hooks/useOrder';
import { IOrder, IOrderPackageItem } from '../../types/order';
import { usePackageUnits } from '../../hooks/usePackageUnits';
import OrderItems, { IOrderItemsRef } from './items';
import { useOrderForm } from '../../hooks/useOrderForm';
import OrderPurchases, { IOrderPurchasesRef } from './purchases';
import { useWarehouses } from '../../hooks/useWarehouses';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

const { useBreakpoint } = Grid;

export interface IMakeOrderStepsProps {
    goNextStep: () => void;
    onBackPress: () => void;
    canMoveBetweenSteps?: boolean;
}

const linearLabels = {
    kg: {
        ru: 'кг.',
        de: 'kg.',
        en: 'kg.',
    },
    cm: {
        ru: 'см.',
        de: 'zm.',
        en: 'cm.',
    },
};

function MakeOrderFirstStep({ goNextStep, canMoveBetweenSteps }: IMakeOrderStepsProps) {
    const order = useOrder();
    const params = useParams<{ type: string; }>();

    const type = order.data?.type || params.type || '';

    const fetch = useFetch();
    const [form] = useForm();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { availableCountries, recipientCountries } = useCountries({ canFetch: type.length > 0, orderType: type });
    const packageUnits = usePackageUnits(true);
    const orderItemsRef = useRef<IOrderItemsRef>();
    const orderPurchasesRef = useRef<IOrderPurchasesRef>();
    const { md } = useBreakpoint();
    const { availableFormItems, loading: availableFormItemsLoading } = useOrderForm(false);
    const warehouses = useWarehouses();
    const [warehouseId, setWarehouseId] = useState<number>();
    const { getFieldMandatory, selectOpen, watchMode } = useFormWatchMode();

    const langTwoLetters = i18n.language.slice(0, 2).toLowerCase() as 'ru' | 'de' | 'en';

    const getPackageUnitsFieldsValue = () => ({
        weight_unit: order.data?.package?.weight_unit || packageUnits.data?.weight[0].value,
        width_unit: order.data?.package?.width_unit || packageUnits.data?.linear[0].value,
        length_unit: order.data?.package?.length_unit || packageUnits.data?.linear[0].value,
        height_unit: order.data?.package?.height_unit || packageUnits.data?.linear[0].value,
    });

    useEffect(() => {
        if (order.data instanceof Object) {
            form.setFieldsValue({
                ...order.data, ...order.data?.package, ...getPackageUnitsFieldsValue(),
            });
        } else {
            form.resetFields();
            form.setFieldsValue(getPackageUnitsFieldsValue());
        }
    }, [order.data, packageUnits.data]);

    useEffect(() => {
        orderPurchasesRef.current?.setSelectedRowKeys(order.data?.purchases || []);
    }, [order.data]);

    useEffect(() => {
        setWarehouseId(typeof order.data?.warehouse_id === 'number' ? order.data.warehouse_id : undefined);
    }, [order.data?.warehouse_id]);

    const processOrderPackage = (orderId: number, values: Record<string, any>) => fetch.request({
        url: order.data?.package instanceof Object ? `/order-packages/${order.data?.package.id}/update` : '/order-packages/create',
        data: {
            ...values,
            order_id: orderId,
        },
        method: 'post',
    });

    const processOrderPackageItems = (orderId: number) => fetch.request<IOrderPackageItem[]>({
        url: `/orders/${orderId}/items/save`,
        data: orderItemsRef.current?.getItems(),
        method: 'post',
    });

    const processOrderPurchases = (orderId: number) => fetch.request<IOrderPackageItem[]>({
        url: '/purchases/attach-to-order',
        data: {
            order_id: orderId,
            purchases: orderPurchasesRef.current?.selectedRowKeys,
        },
        method: 'post',
    });

    const onFinish = async (values: Record<string, any>) => {
        const body = {
            ...values,
            type,
        };

        const result = await fetch.request<IOrder>({
            url: typeof order.data?.id !== 'undefined' ? `/orders/${order.data.id}/update` : '/orders/create',
            data: body,
            method: 'post',
        });
        if (result.success && result.data) {
            let canGoNextStep = true;

            if (availableFormItems.order_package?.types.includes(type)) {
                const opResult = await processOrderPackage(result.data.id, values);
                if (opResult.success) {
                    const x = await processOrderPackageItems(result.data.id);
                    if (!x.success) {
                        canGoNextStep = false;
                    }
                } else {
                    canGoNextStep = false;
                }
            }

            if (availableFormItems.order_purchases?.types.includes(type)) {
                const x = await processOrderPurchases(result.data.id);
                if (!x.success) {
                    canGoNextStep = false;
                }
            }

            await order.update(result.data.id);

            if (canGoNextStep && canMoveBetweenSteps && typeof order.data?.id === 'number') {
                await goNextStep();
            }

            if (typeof order.data?.id === 'undefined') {
                navigate(`/order/${result.data.id}`);
            }
        }
    };

    const onSelectFilterOption = (input: string, option?: BaseOptionType) => (option?.label?.toString().toLocaleLowerCase() ?? '').includes(input.toLowerCase());

    return (
        <Spin spinning={order.dataLoading || availableFormItemsLoading}>
            <Form form={form} initialValues={{ ...order.data, ...order.data?.package }} layout="vertical" onFinish={onFinish}>
                <Row gutter={[30, 30]}>
                    {availableFormItems.from?.types?.includes(type) && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                className="ant-form-item-with-extra"
                                label={t('orderFrom')}
                                name="from"
                                rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderFrom')) }]}
                            >
                                <Select
                                    open={selectOpen}
                                    showSearch
                                    options={availableCountries.data}
                                    loading={availableCountries.loading}
                                    filterOption={onSelectFilterOption}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col xs={24} md={12}>
                        <Form.Item
                            className="ant-form-item-with-extra"
                            label={t('orderTo')}
                            name="to"
                            rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderTo')) }]}
                        >
                            <Select
                                open={selectOpen}
                                showSearch
                                options={recipientCountries.data}
                                loading={recipientCountries.loading}
                                filterOption={onSelectFilterOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={[30, 30]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('parcelInsurance')}
                                    name="insurance"
                                >
                                    <Select
                                        open={selectOpen}
                                        defaultValue={false}
                                        options={[{
                                            label: t('yes'),
                                            value: true,
                                        }, {
                                            label: t('no'),
                                            value: false,
                                        }]}
                                    />
                                </Form.Item>
                            </Col>
                            {availableFormItems.order_purchases?.types.includes(type) && (
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        className="ant-form-item-with-extra"
                                        label={t('warehouse')}
                                        rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('warehouse')) }]}
                                    >
                                        <Select
                                            open={selectOpen}
                                            value={warehouseId}
                                            loading={warehouses.loading}
                                            options={warehouses.selectOptions}
                                            onSelect={setWarehouseId}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    {availableFormItems.order_package?.types?.includes(type) && (
                        <>
                            <Col xs={24}>
                                <Space direction={md ? 'horizontal' : 'vertical'} size="large">
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24}>
                                            <Typography.Title level={5}>
                                                {t('regularShipment')}
                                            </Typography.Title>
                                        </Col>
                                        <Col xs={24}>
                                            <Space direction="vertical">
                                                <span className="fw-400">
                                                    {t('maxLength')}
                                                    :
                                                    {' '}
                                                    {`105${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxWeight')}
                                                    :
                                                    {' '}
                                                    {`20.0${linearLabels.kg[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxGirth')}
                                                    :
                                                    {' '}
                                                    {`200${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24}>
                                            <Typography.Title level={5}>
                                                {t('expressShipment')}
                                            </Typography.Title>
                                        </Col>
                                        <Col xs={24}>
                                            <Space direction="vertical">
                                                <span className="fw-400">
                                                    {t('maxLength')}
                                                    :
                                                    {' '}
                                                    {`150${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxWeight')}
                                                    :
                                                    {' '}
                                                    {`30.0${linearLabels.kg[langTwoLetters]}`}
                                                </span>
                                                <span className="fw-400">
                                                    {t('maxGirth')}
                                                    :
                                                    {' '}
                                                    {`300${linearLabels.cm[langTwoLetters]}`}
                                                </span>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>
                            <Col xs={24}>
                                <OrderItems ref={orderItemsRef} orderForm={form} />
                            </Col>
                        </>
                    )}
                    {availableFormItems.order_package?.types?.includes(type) && (
                        <>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageWeight')}
                                    name="weight"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                                >
                                    <Input
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        suffix={packageUnits.data?.weight[0].label}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="weight_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageWidth')}
                                    name="width"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageWidth')) }]}
                                >
                                    <Input suffix={packageUnits.data?.linear[0].label} />
                                </Form.Item>
                                <Form.Item hidden name="width_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageLength')}
                                    name="length"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageLength')) }]}
                                >
                                    <Input suffix={packageUnits.data?.linear[0].label} />
                                </Form.Item>
                                <Form.Item hidden name="length_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageHeight')}
                                    name="height"
                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackageHeight')) }]}
                                >
                                    <Input suffix={packageUnits.data?.linear[0].label} />
                                </Form.Item>
                                <Form.Item hidden name="height_unit" />
                            </Col>
                        </>
                    )}
                    {availableFormItems.order_purchases?.types.includes(type) && (
                        <Col xs={24}>
                            <OrderPurchases
                                ref={orderPurchasesRef}
                                warehouseId={warehouseId}
                            />
                        </Col>
                    )}
                    <Col className="text-center" xs={24}>
                        <Button hidden={watchMode} type="primary" htmlType="submit" loading={fetch.loading}>
                            {canMoveBetweenSteps ? t('next') : t('update')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
}

export default MakeOrderFirstStep;
