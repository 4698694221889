import { useState } from 'react';
import { useFetch } from './useFetch';
import { ICustomsRegulations } from '../types/customs-regulations';

export const useCustomsRegulations = () => {
    const { loading, request } = useFetch();
    const [data, setData] = useState<ICustomsRegulations>();

    const getCustomsRegulations = async (from?: string) => {
        const response = await request<ICustomsRegulations>({
            url: '/customs-regulations',
            method: 'post',
            data: { from },
        });
        if (response.success && response.data instanceof Object) {
            setData(response.data);
        }
    };

    return {
        data,
        getCustomsRegulations,
        loading,
    };
};
