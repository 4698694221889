import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Modal } from 'antd';
import { IListResponse } from '../types/list';
import { useFetch } from './useFetch';

export const useDeliveryTypeCommissions = () => {
    const dataFetch = useFetch();
    const deleteFetch = useFetch();
    const [data, setData] = useState<IListResponse>();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const page = searchParams.get('page');
    const { id } = useParams();

    const getData = async () => {
        let url = '/delivery/types/commissions';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await dataFetch.request<IListResponse>({
            url,
            method: 'post',
            data: {
                filters: [{
                    column: 'delivery_type_id',
                    values: [id],
                }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    const deleteRow = (x: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/delivery/types/commissions/${x}/delete`,
                method: 'get',
            }).then(() => getData()),
            okText: t('delete'),
        });
    };

    useEffect(() => {
        getData();
    }, [page]);

    return {
        deleteRow,
        list: {
            data,
            loading: dataFetch.loading,
        },
    };
};
