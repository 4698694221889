import { useContext, useEffect } from 'react';
import { OrderContext } from '../context/order';
import { IOrder } from '../types/order';
import { useFetch } from './useFetch';

interface IUseOrderOptions {
    fetchData: boolean;
    orderId?: number | string;
}

const defaultOptions: IUseOrderOptions = {
    fetchData: false,
};

export const useOrder = ({ fetchData, orderId } = defaultOptions) => {
    const context = useContext(OrderContext);
    const fetch = useFetch();

    const clear = () => context.setData(undefined);

    const getData = async (id?: number | string) => {
        if (typeof id !== 'undefined') {
            context.setDataLoading(true);

            const result = await fetch.request<IOrder>({
                url: `/orders/${id}`,
            });

            if (result.success || (!result.success && typeof result.data?.id === 'number')) {
                context.setData(result.data);
            }

            context.setDataLoading(false);
        } else {
            clear();
        }
    };

    const update = (id?: number | string) => getData(id || orderId);

    useEffect(() => {
        if (fetchData) {
            getData(orderId);
        }
    }, [fetchData]);

    return {
        clear,
        data: context.data,
        dataLoading: context.dataLoading,
        update,
    };
};
