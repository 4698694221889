import {
    Button,
    Card, Col, Row, Space, Spin,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrder } from '../hooks/useOrder';
import MakeOrderFirstStep from '../components/make-order-steps/first';
import MakeOrderSecondStep from '../components/make-order-steps/second';
import MakeOrderThirdStep from '../components/make-order-steps/third';
import MakeOrderMemberStep from '../components/make-order-steps/member';
import MakeOrderDeliveryStep from '../components/make-order-steps/delivery';
import Collapse from '../components/collapse';
import { useOrderForm } from '../hooks/useOrderForm';
import { useFetch } from '../hooks/useFetch';

function OrderPage() {
    const notifyServicesFetch = useFetch();
    const { id } = useParams();
    const order = useOrder({ fetchData: true, orderId: id });
    const { t } = useTranslation();
    const { availableFormItems } = useOrderForm();

    const type = order.data?.type || '';

    const onBackPress = () => {};

    const goNextStep = () => {};

    const notifyServices = async () => {
        await notifyServicesFetch.request({
            url: `/orders/${order.data?.id}/notify-third-party-services`,
            method: 'get',
        });
        order.update();
    };

    return (
        <Spin spinning={order.dataLoading}>
            <Card title={`${t('orderInfo')}${id}`}>
                <fieldset disabled={false}>
                    <Row gutter={[30, 30]}>
                        {order.data?.paid_data_updated && (
                            <Col xs={24}>
                                <Space direction="vertical">
                                    <Button type="primary" onClick={notifyServices} loading={notifyServicesFetch.loading}>
                                        {t('notify')}
                                    </Button>
                                    <span>{t('paidDataUpdated')}</span>
                                </Space>
                            </Col>
                        )}
                        <Col xs={24}>
                            <Collapse title={t('orderFromTo')}>
                                <MakeOrderFirstStep onBackPress={onBackPress} goNextStep={goNextStep} />
                            </Collapse>
                        </Col>
                        {availableFormItems.order_package?.types.includes(type) && (
                            <Col xs={24}>
                                <Collapse title={t('orderTransportation')}>
                                    <MakeOrderSecondStep onBackPress={onBackPress} goNextStep={goNextStep} />
                                </Collapse>
                            </Col>
                        )}
                        <Col xs={24}>
                            <Collapse title={t('orderFiller')}>
                                <MakeOrderThirdStep onBackPress={onBackPress} goNextStep={goNextStep} />
                            </Collapse>
                        </Col>
                        {availableFormItems.sender?.types.includes(type) && (
                            <Col xs={24}>
                                <Collapse title={t('orderSender')}>
                                    <MakeOrderMemberStep type="SENDER" onBackPress={onBackPress} goNextStep={goNextStep} />
                                </Collapse>
                            </Col>
                        )}
                        <Col xs={24}>
                            <Collapse title={t('orderRecipient')}>
                                <MakeOrderMemberStep type="RECIPIENT" onBackPress={onBackPress} goNextStep={goNextStep} />
                            </Collapse>
                        </Col>
                        {order.data?.shouldSelectDeliveryType && (
                            <Col xs={24}>
                                <Collapse title={t('deliveryType')}>
                                    <MakeOrderDeliveryStep onBackPress={onBackPress} goNextStep={goNextStep} />
                                </Collapse>
                            </Col>
                        )}
                    </Row>
                </fieldset>
            </Card>
        </Spin>
    );
}

export default OrderPage;
