import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { IListResponse } from '../types/list';
import { useFetch } from './useFetch';
import { IPromoCode } from '../types/promo-code';

export const usePromoCodes = () => {
    const deleteFetch = useFetch();
    const fetch = useFetch();
    const [response, setResponse] = useState<IListResponse<IPromoCode>>();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const page = searchParams.get('page');

    const deleteRow = (id: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/promo-codes/${id}/delete`,
                method: 'get',
            }).then(() => getList()),
            okText: t('delete'),
        });
    };

    const getList = async () => {
        let url = '/promo-codes';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await fetch.request({
            url,
            method: 'post',
            data: {
                sorters: [{ column: 'id', desc: true }],
            },
        });

        if (result.success) {
            setResponse(result.data);
        }
    };

    useEffect(() => {
        getList();
    }, [page]);

    return {
        response,
        deleteRow,
        loading: fetch.loading,
    };
};
