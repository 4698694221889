import {
    Button, Card, Col, Form, Input, Row, Select, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useWarehouses } from '../../hooks/useWarehouses';
import { usePurchase } from '../../hooks/usePurchase';
import PurchaseItemsTable from '../../components/purchase-items-table';

function PurchaseFormPage() {
    const { t } = useTranslation();
    const {
        form, formData, formDataLoading, save, saveLoading,
    } = usePurchase();
    const warehouses = useWarehouses();
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const defaultWarehouseId = typeof searchParams.get('warehouse_id') === 'string' ? parseInt(searchParams.get('warehouse_id') as string, 10) : undefined;
    const newRow = id === 'create';

    useEffect(() => {
        if (typeof defaultWarehouseId === 'number') {
            form.setFieldValue('warehouse_id', defaultWarehouseId);
        }
    }, [defaultWarehouseId]);

    const title = (
        <Space direction="vertical">
            <span>{newRow ? t('purchaseAdding') : t('purchases')}</span>
            {!newRow && <span>{`№${id}`}</span>}
        </Space>
    );

    return (
        <div className="container">
            <Card className="card-header-wo-border" title={title} loading={formDataLoading}>
                <Form form={form} layout="vertical" onFinish={save}>
                    <Row gutter={[30, 30]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t('warehouse')}
                                name="warehouse_id"
                                rules={[{ required: true, message: getRequiredFieldMessage(t('warehouse')) }]}
                            >
                                <Select
                                    options={warehouses.selectOptions}
                                    loading={warehouses.loading}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                className="ant-form-item-with-extra"
                                label={t('goodName')}
                                name="name"
                                rules={[{ required: true, message: getRequiredFieldMessage(t('name')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t('trackingNumber')}
                                name="tracking_code"
                                rules={[{ required: true, message: getRequiredFieldMessage(t('trackingNumber')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t('shopName')}
                                name="shop_name"
                                rules={[{ required: true, message: getRequiredFieldMessage(t('shopName')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        {!newRow && formData && (
                            <Col xs={24}>
                                <PurchaseItemsTable onFormHide={() => {}} purchase={formData} />
                            </Col>
                        )}
                        <Col className="text-center" xs={24}>
                            <Space className="form-action-buttons">
                                <Button type="link" onClick={() => navigate(-1)}>
                                    {t('back')}
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={saveLoading}
                                >
                                    {t('save')}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
}

export default PurchaseFormPage;
