import {
    useEffect, useState, useRef, forwardRef, useImperativeHandle,
} from 'react';
import {
    Button, Space, Modal, Row, Col, FormInstance,
} from 'antd';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useOrder } from '../../hooks/useOrder';
import Table from '../table';
import OrderItemForm, { OrderItemFormRef, OrderItemFormValues } from './item-form';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

export interface IOrderItemsRef {
    getItems: () => OrderItemFormValues[];
}

interface IOrderItemsProps {
    orderForm: FormInstance;
}

const OrderItems = forwardRef(({ orderForm }: IOrderItemsProps, ref) => {
    const order = useOrder();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState<OrderItemFormValues[]>([]);
    const form = useRef<OrderItemFormRef>();
    const { watchMode } = useFormWatchMode();

    const actions = (_: any, row: OrderItemFormValues) => (
        <Space>
            <Button icon={<EditOutlined />} type="link" onClick={() => onEditClick(row)} />
            <Button icon={<DeleteOutlined />} type="link" onClick={() => onDeleteClick(row)} />
        </Space>
    );

    const getIndex = (row: OrderItemFormValues) => {
        if (typeof row.id === 'number') {
            return dataSource.findIndex((el) => el.id === row.id);
        }
        if (typeof row.newRowId === 'number') {
            return dataSource.findIndex((el) => el.newRowId === row.newRowId);
        }
        return -1;
    };

    const onEditClick = (row: OrderItemFormValues) => {
        if (form.current) {
            const index = getIndex(row);
            if (index > -1) {
                form.current.show(row, index);
            }
        }
    };

    const onDeleteClick = (row: OrderItemFormValues) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => {
                const index = getIndex(row);
                if (index > -1) {
                    setDataSource(produce(dataSource, (draft) => {
                        draft?.splice(index, 1);
                    }));
                }
            },
            okText: t('delete'),
        });
    };

    const onFormSubmit = (values: OrderItemFormValues) => {
        const { index } = values;
        if (typeof index === 'undefined') {
            setDataSource(produce(dataSource, (draft) => {
                draft.unshift(values);
            }));
        } else if (typeof index !== 'undefined') {
            setDataSource(produce(dataSource, (draft) => {
                if (draft[index]) {
                    draft[index] = values;
                }
            }));
        }
    };

    const onPlusClick = () => {
        if (form.current) {
            form.current.show();
        }
    };

    useEffect(() => {
        setDataSource(order.data?.package?.items || []);
    }, [order.data]);

    useImperativeHandle(ref, () => ({
        getItems: () => dataSource || [],
    }));

    return (
        <Row gutter={[30, 30]}>
            <OrderItemForm ref={form} orderForm={orderForm} onSubmit={onFormSubmit} />
            {!watchMode && (
                <Col xs={24}>
                    <Button type="primary" onClick={onPlusClick}>
                        Заполнить данные
                    </Button>
                </Col>
            )}
            <Col xs={24}>
                <Table
                    scroll={{ x: true }}
                    dataSource={dataSource}
                    bordered
                    columns={[{
                        align: 'center',
                        dataIndex: 'name',
                        title: t('orderPackageName'),
                    }, {
                        align: 'center',
                        dataIndex: 'count',
                        title: t('orderPackageItemsCount'),
                    }, {
                        align: 'center',
                        dataIndex: 'cost',
                        title: t('orderPackageCost'),
                    }, ...(watchMode ? [] : [{
                        dataIndex: '',
                        render: actions,
                        width: 100,
                    }])]}
                    pagination={false}
                />
            </Col>
        </Row>
    );
});

export default OrderItems;
