import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../context/app';
import { IOrderPackageUnits } from '../types/order';
import { useFetch } from './useFetch';

export const usePackageUnits = (canFetch = false) => {
    const context = useContext(AppContext);
    const fetchUnits = useFetch();
    const { i18n } = useTranslation();

    const getPackageUnits = async () => {
        const result = await fetchUnits.request<IOrderPackageUnits>({
            url: '/order-packages/units',
        });
        if (result.success && result.data) {
            context.setPackageUnits(result.data);
        }
    };

    useEffect(() => {
        if (canFetch) {
            getPackageUnits();
        }
    }, [i18n.language, canFetch]);

    return {
        loading: fetchUnits.loading,
        data: context.packageUnits,
    };
};
