import { useEffect, useState } from 'react';
import { IOrderPackageUnits } from '../types/order';
import { useFetch } from './useFetch';

export const useOrderPackageUnits = () => {
    const fetchUnits = useFetch();
    const [packageUnits, setPackageUnits] = useState<IOrderPackageUnits>();

    const getPackageUnits = async () => {
        const result = await fetchUnits.request<IOrderPackageUnits>({
            url: '/order-packages/units',
        });
        if (result.success && result.data) {
            setPackageUnits(result.data);
        }
    };

    useEffect(() => {
        getPackageUnits();
    }, []);

    return {
        loading: fetchUnits.loading,
        packageUnits,
    };
};
