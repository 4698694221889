import { DefaultOptionType } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IListResponse } from '../types/list';
import { useFetch } from './useFetch';

interface IUseCountriesOptions {
    canFetch: boolean;
    orderType?: string;
}

const defaultOptions:IUseCountriesOptions = {
    canFetch: true,
};

export const useCountries = ({ canFetch, orderType } = defaultOptions) => {
    const acFetch = useFetch();
    const countriesFetch = useFetch();
    const rcFetch = useFetch();
    const [availableCountries, setAvailableCountries] = useState<DefaultOptionType[]>([]);
    const [countries, setCountries] = useState<DefaultOptionType[]>([]);
    const [recipientCountries, setRecipientCountries] = useState<DefaultOptionType[]>([]);
    const { i18n } = useTranslation();

    const getAvailableCountries = async () => {
        const result = await acFetch.request<IListResponse<DefaultOptionType>>({
            url: '/available-countries/select',
            method: 'post',
            data: {
                count: 1000,
            },
        });
        if (result.success && result.data && Array.isArray(result.data.list)) {
            setAvailableCountries(result.data.list);
        }
    };

    const getCountries = async () => {
        const result = await countriesFetch.request<IListResponse<DefaultOptionType>>({
            url: '/countries/select',
            method: 'post',
            data: {
                count: 1000,
            },
        });
        if (result.success && result.data && Array.isArray(result.data.list)) {
            setCountries(result.data.list);
        }
    };

    const getRecipientCountries = async () => {
        const result = await rcFetch.request<IListResponse<DefaultOptionType>>({
            url: '/recipient-countries/select',
            method: 'post',
            data: {
                count: 1000,
                orderType,
            },
        });
        if (result.success && result.data && Array.isArray(result.data.list)) {
            setRecipientCountries(result.data.list);
        }
    };

    useEffect(() => {
        if (canFetch) {
            getAvailableCountries();
            getCountries();
        }
    }, [canFetch, i18n.language]);

    useEffect(() => {
        if (canFetch) {
            getRecipientCountries();
        }
    }, [canFetch, i18n.language, orderType]);

    return {
        availableCountries: {
            data: availableCountries,
            loading: acFetch.loading,
        },
        countries: {
            data: countries,
            loading: countriesFetch.loading,
        },
        recipientCountries: {
            data: recipientCountries,
            loading: rcFetch.loading,
        },
    };
};
