import { notification } from 'antd';
import { useEffect } from 'react';
import { IGeneralSetting } from '../types/general-settings';
import { useFetch } from './useFetch';
import { useForm } from './useForm';

export const useGeneralSettings = () => {
    const [form] = useForm();
    const fetch = useFetch();

    const getData = async () => {
        const result = await fetch.request<IGeneralSetting>({
            url: '/settings',
            method: 'get',
        });
        if (result.success && result.data) {
            form.setFieldsValue(result.data);
        }
    };

    const save = async (values: IGeneralSetting) => {
        const result = await fetch.request<IGeneralSetting>({
            url: '/settings/update',
            method: 'post',
            data: values,
        });
        if (result.success) {
            notification.success({
                message: result.message,
            });
            getData();
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        form,
        loading: fetch.loading,
        save,
    };
};
