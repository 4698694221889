import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button, Card, Modal, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { IListResponse } from '../../types/list';
import Table from '../table';

function RusPostTypePrices() {
    const fetch = useFetch();
    const deleteFetch = useFetch();
    const [data, setData] = useState<IListResponse>();
    const { t } = useTranslation();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    const getData = async () => {
        let url = '/ruspost/prices';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await fetch.request<IListResponse>({
            url,
            method: 'post',
            data: {
                filters: [{
                    column: 'delivery_type_id',
                    values: [id],
                }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useEffect(() => {
        getData();
    }, [id, page]);

    const onDeleteClick = async (priceId:number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/ruspost/prices/${priceId}/delete`,
                method: 'get',
            }).then(() => getData()),
            okText: t('delete'),
        });
    };

    const actions = (_: any, row: any) => (
        <Space>
            <Link to={`/delivery/type/${id}/price/${row.id}`}>
                <Button
                    icon={<EditOutlined />}
                    type="primary"
                    href={`/delivery/type/${id}/price/${row.id}`}
                />
            </Link>
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => onDeleteClick(row.id)}
            />
        </Space>
    );

    const extra = (
        <Link to={`/delivery/type/${id}/price/create`}>
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('prices')}>
            <Table
                loading={fetch.loading}
                dataSource={data?.list}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                }}
                columns={[{
                    dataIndex: 'weight',
                    title: t('orderPackageWeight'),
                }, {
                    dataIndex: 'price',
                    title: t('price'),
                }, {
                    dataIndex: '',
                    render: actions,
                    width: 65,
                }]}
            />
        </Card>
    );
}

export default RusPostTypePrices;
