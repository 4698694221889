import { Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import AuthedLayout from './authed-layout';
import OrderContextWrapper from '../context/order';

function AuthedRoutes() {
    const user = useUser();

    if (user.loading) {
        return (
            <div className="d-flex flex-1 align-items-center justify-content-center">
                <Spin size="large" />
            </div>
        );
    }
    if (typeof user.info !== 'undefined') {
        return (
            <OrderContextWrapper>
                <AuthedLayout>
                    <Outlet />
                </AuthedLayout>
            </OrderContextWrapper>
        );
    }
    return null;
}

export default AuthedRoutes;
