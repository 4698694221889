import { useEffect, useState } from 'react';
import { useFetch } from './useFetch';

interface ICountry {
    geonameid: number;
    name: string;
    iso: string;
    phone_code: string;
    postal_code_format: string;
    postal_code_regex: string;
    latitude: number;
    longitude: number;
}

export const useCountry = (code?: string) => {
    const [data, setData] = useState<ICountry>();
    const fetch = useFetch();

    const getData = async () => {
        if (typeof code === 'string') {
            const result = await fetch.request({
                url: `/countries/${code}`,
            });
            if (result.success) {
                setData(result.data);
            }
        } else {
            setData(undefined);
        }
    };

    useEffect(() => {
        getData();
    }, [code]);

    return {
        data,
        loading: fetch.loading,
    };
};
