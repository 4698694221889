import React, { useEffect, useState } from 'react';
import {
    Drawer, Grid, Layout,
} from 'antd';
import Header from '../components/header';
import Menu from '../components/menu';

import '../styles/authed-layout.less';

const { useBreakpoint } = Grid;

const {
    Content, Sider,
} = Layout;

function AuthedLayout({ children }: React.PropsWithChildren) {
    const { md } = useBreakpoint();
    const [collapsed, setCollapsed] = useState(false);
    const [drawer, setDrawer] = useState(false);

    const onDrawerClose = () => setDrawer(false);

    const onMenuClick = () => setDrawer((value) => !value);

    useEffect(() => {
        if (md) {
            setDrawer(false);
        }
    }, [md]);

    return (
        <Layout>
            <Sider
                width={270}
                hidden={!md}
                breakpoint="lg"
                collapsible
                collapsedWidth={!md ? 0 : undefined}
                theme="light"
                onCollapse={setCollapsed}
            >
                <Menu collapsed={collapsed} />
            </Sider>
            <Drawer
                placement="left"
                open={!md && drawer}
                onClose={onDrawerClose}
                width={250}
                bodyStyle={{ padding: 0 }}
                closable={false}
            >
                <Menu />
            </Drawer>
            <Layout>
                <Header showMenuBtn={!md} onMenuClick={onMenuClick} />
                <Content className="authed-layout-content">
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}

export default AuthedLayout;
