import {
    Button, Col, DatePicker, Form, Row, Select, Space,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useOrder } from '../../hooks/useOrder';
import { IOrder } from '../../types/order';
import { IMakeOrderStepsProps } from './first';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

function MakeOrderSecondStep({ onBackPress, goNextStep, canMoveBetweenSteps }: IMakeOrderStepsProps) {
    const saveFetch = useFetch();
    const fetchOptions = useFetch();
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const order = useOrder();
    const { t, i18n } = useTranslation();
    const [form] = useForm();
    const [deliveryToCompanyType, setDeliveryToCompanyType] = useState<string>();
    const { getFieldMandatory, selectOpen, watchMode } = useFormWatchMode();

    const getOptions = async () => {
        const result = await fetchOptions.request<DefaultOptionType[]>({
            url: `/orders/transfer-options?orderId=${order.data?.id}`,
        });
        if (result.success && Array.isArray(result.data)) {
            setOptions(result.data);
        }
    };

    useEffect(() => {
        if (typeof order.data?.id === 'number') {
            getOptions();
        }
    }, [i18n.language, order.data?.id]);

    useEffect(() => {
        if (typeof order.data?.delivery_to_company_type === 'string') {
            setDeliveryToCompanyType(order.data?.delivery_to_company_type);
        }
    }, [order.data?.delivery_to_company_type]);

    useEffect(() => {
        form.setFieldsValue({
            ...order.data,
            pickup_date: typeof order.data?.pickup_date === 'string' ? moment(order.data?.pickup_date) : undefined,
        });
    }, [order.data]);

    const onFinish = async (values: Record<string, any>) => {
        if (typeof values.pickup_date !== 'undefined' && values.pickup_date instanceof moment) {
            values.pickup_date = moment(values.pickup_date).format('YYYY-MM-DD HH:mm');
        }

        const result = await saveFetch.request<IOrder>({
            url: `/orders/${order.data?.id}/update`,
            data: values,
            method: 'post',
        });
        if (result.success) {
            await order.update(result.data?.id);
            if (canMoveBetweenSteps) {
                goNextStep();
            }
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={[30, 30]}>
                <Col xs={24} md={12}>
                    <Form.Item
                        label={t('orderDeliveryToCompanyTypeTitle')}
                        name="delivery_to_company_type"
                        rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderDeliveryToCompanyTypeTitle')) }]}
                    >
                        <Select
                            open={selectOpen}
                            loading={fetchOptions.loading}
                            options={options}
                            onSelect={setDeliveryToCompanyType}
                        />
                    </Form.Item>
                </Col>
                {deliveryToCompanyType === 'BY_COURIER' && (
                    <Col xs={24} md={12}>
                        <Form.Item
                            className="ant-form-item-with-extra"
                            label={t('orderPackagePickupDate')}
                            name="pickup_date"
                            rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackagePickupDate')) }]}
                        >
                            <DatePicker
                                open={selectOpen}
                                className="w-100"
                                format="DD.MM.YYYY HH:mm"
                                showTime
                                disabledDate={(curr) => curr < moment()}
                                showNow={false}
                                allowClear={!watchMode}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col className="text-center" xs={24}>
                    <Space className="form-action-buttons" direction="horizontal" hidden={watchMode} size="large">
                        {canMoveBetweenSteps && (
                            <Button type="link" onClick={onBackPress}>
                                {t('back')}
                            </Button>
                        )}
                        <Button type="primary" htmlType="submit" loading={saveFetch.loading || order.dataLoading}>
                            {canMoveBetweenSteps ? t('next') : t('update')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
}

export default MakeOrderSecondStep;
