import { PercentageOutlined, SaveOutlined } from '@ant-design/icons';
import {
    Card, Form, Col, Row, Input, Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useCustomDuty } from '../../hooks/useCustomDuty';

function CustomDuty() {
    const { form, loading, save } = useCustomDuty();
    const { t } = useTranslation();

    return (
        <Card title={t('customsDuties')} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            required
                            name="free_amount"
                            label={t('orderPackageCost')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageCost')) }]}
                        >
                            <Input type="number" suffix="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            required
                            name="procent"
                            label={t('serviceCommissions')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('serviceCommissions')) }]}
                        >
                            <Input type="number" suffix={<PercentageOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    htmlType="submit"
                    type="primary"
                    icon={<SaveOutlined />}
                >
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default CustomDuty;
