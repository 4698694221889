import { useEffect, useState } from 'react';
import { IServiceBalance } from '../types/service-balance';
import { useFetch } from './useFetch';

export const useServiceBalance = () => {
    const fetch = useFetch();
    const [balance, setBalance] = useState<IServiceBalance[]>();

    const getBalance = async () => {
        const result = await fetch.request({
            url: '/service-balance',
        });
        if (result.success) {
            setBalance(result.data);
        }
    };

    useEffect(() => {
        getBalance();
    }, []);

    return {
        balance,
        loading: fetch.loading,
    };
};
