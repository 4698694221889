import React, { useMemo } from 'react';
import { ConfigProvider } from 'antd';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import { useTranslation } from 'react-i18next';

interface IAntdContext {}

export const AntdContext = React.createContext<IAntdContext>({});

const languages: Record<string, any> = {
    'de-DE': deDe,
    'ru-RU': ruRU,
    'en-EN': enUS,
};

function AntdContextWrapper({ children }: React.PropsWithChildren) {
    const { i18n } = useTranslation();

    const value = useMemo(() => ({}), [i18n.language]);

    return (
        <AntdContext.Provider value={value}>
            <ConfigProvider locale={languages[i18n.language]}>
                {children}
            </ConfigProvider>
        </AntdContext.Provider>
    );
}

export default AntdContextWrapper;
