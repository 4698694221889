import React, { PropsWithChildren, useMemo, useState } from 'react';
import { IOrder } from '../types/order';

export type TOrderAvailableFormItems = Record<string, {
    memberTypes: Array<'RECIPIENT'|'SENDER'>,
    types: string[],
}>;

interface IOrderContext {
    data?: IOrder;
    setData: Function;
    dataLoading?: boolean;
    setDataLoading: Function;
    availableFormItems: TOrderAvailableFormItems;
    setAvailableFormItems: Function;
    availableFormItemsLoading: boolean;
    setAvailableFormItemsLoading: Function;
}

export const OrderContext = React.createContext<IOrderContext>({
    setData: () => {},
    setDataLoading: () => {},
    availableFormItems: {},
    setAvailableFormItems: () => {},
    availableFormItemsLoading: false,
    setAvailableFormItemsLoading: () => {},
});

function OrderContextWrapper({ children }: PropsWithChildren) {
    const [availableFormItems, setAvailableFormItems] = useState<TOrderAvailableFormItems>({});
    const [availableFormItemsLoading, setAvailableFormItemsLoading] = useState(false);

    const [data, setData] = useState();
    const [dataLoading, setDataLoading] = useState(false);

    const value = useMemo(() => ({
        availableFormItems,
        setAvailableFormItems,
        availableFormItemsLoading,
        setAvailableFormItemsLoading,
        data,
        setData,
        dataLoading,
        setDataLoading,
    }), [availableFormItems, data, dataLoading]);

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
}

export default OrderContextWrapper;
