import {
    Button, Col, DatePicker, Form, Input, Row, Space, Typography,
} from 'antd';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useCountry } from '../../hooks/useCountry';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useOrder } from '../../hooks/useOrder';
import { IOrderMember } from '../../types/order';
import PhoneInput from '../phone-input';
import { IMakeOrderStepsProps } from './first';
import { useOrderForm } from '../../hooks/useOrderForm';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

interface IMakeOrderMemberStepProps {
    type: 'SENDER' | 'RECIPIENT';
}

function MakeOrderMemberStep({
    onBackPress, goNextStep, type, canMoveBetweenSteps,
}: IMakeOrderMemberStepProps & IMakeOrderStepsProps) {
    const saveFetch = useFetch();
    const order = useOrder();
    const { t } = useTranslation();
    const [form] = useForm();
    const countryISO = type === 'RECIPIENT' ? order.data?.to : order.data?.from;
    const countryData = useCountry(countryISO);
    const { availableFormItems } = useOrderForm(false);
    const { getFieldMandatory, watchMode } = useFormWatchMode();

    const labelByType = type === 'RECIPIENT' ? 'Recipient' : 'Sender';
    const target = type === 'RECIPIENT' ? order.data?.recipient : order.data?.sender;

    const onFinish = async (values: Record<string, any>) => {
        const result = await saveFetch.request<IOrderMember>({
            url: typeof target?.id !== 'undefined' ? `/order-members/${target.id}/update` : '/order-members/create',
            data: values,
            method: 'post',
        });
        if (result.success) {
            await order.update(result.data?.order_id);
            if (canMoveBetweenSteps) {
                goNextStep();
            }
        }
    };

    const initialValues = {
        type,
        order_id: order.data?.id,
        ...target,
        passport_date: typeof target?.passport_date === 'string' ? moment(target.passport_date) : undefined,
    };

    return (
        <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
            <Row gutter={[30, 30]}>
                <Col xs={24} md={12} xl={8}>
                    <Form.Item
                        extra={t('orderMemberFullNameInfo')}
                        label={t(`orderMember${labelByType}Name`)}
                        name="name"
                        rules={[
                            { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Name`)) },
                            { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                        ]}
                    >
                        <Input placeholder="Ivan" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={8}>
                    <Form.Item
                        extra={t('orderMemberFullNameInfo')}
                        label={t(`orderMember${labelByType}Surname`)}
                        name="surname"
                        rules={[
                            { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Surname`)) },
                            { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                        ]}
                    >
                        <Input placeholder="Ivanov" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={8}>
                    <Form.Item
                        label={t(`orderMember${labelByType}Middlename`)}
                        name="middle_name"
                        rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                    >
                        <Input placeholder="Ivanovich" />
                    </Form.Item>
                </Col>
                {availableFormItems.representative?.memberTypes?.includes(type) && (
                    <Col xs={24}>
                        <Row>
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t(`orderMember${labelByType}Representative`)}
                                    name="representative"
                                    rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                                >
                                    <Input placeholder={t('representativePlaceholder') as string} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                )}
                {availableFormItems.phone?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label={t(`orderMember${labelByType}Phone`)}
                            name="phone"
                            rules={[
                                { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Phone`)) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                {
                                    validator: async (_: any, value: string) => {
                                        if (typeof countryISO === 'string' && !isValidPhoneNumber(value, countryISO as CountryCode)) {
                                            throw new Error(t('incorrectPhoneNumber') as string);
                                        }
                                    },
                                },
                            ]}
                        >
                            <PhoneInput
                                defaultCountry={type === 'SENDER' ? order.data?.from : undefined}
                                country={type === 'RECIPIENT' ? order.data?.to : undefined}
                            />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.email?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label={t(`orderMember${labelByType}Email`)}
                            name="email"
                            rules={[
                                { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Email`)) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input placeholder="ivan@example.com" />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.region?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            extra={t('orderMemberRegionInfo')}
                            label={t(`orderMember${labelByType}Region`)}
                            name="region"
                            rules={[
                                { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Region`)) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input placeholder="Moscow" />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.city?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            extra={t('orderMemberCityInfo')}
                            label={t(`orderMember${labelByType}City`)}
                            name="city"
                            rules={[
                                { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}City`)) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input placeholder="Moscow" />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.street?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            extra={t('orderMemberStreetInfo')}
                            label={t(`orderMember${labelByType}Street`)}
                            name="street"
                            rules={[
                                { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Street`)) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input placeholder="Lenina" />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.postal_code?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label={t(`orderMember${labelByType}PostalCode`)}
                            name="postal_code"
                            rules={[{
                                required: getFieldMandatory(true),
                                pattern: typeof countryData.data?.postal_code_regex === 'string'
                                    ? new RegExp(countryData.data.postal_code_regex) : undefined,
                                message: getRequiredFieldMessage(t(`orderMember${labelByType}PostalCode`)),
                            }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.house?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label={t(`orderMember${labelByType}House`)}
                            name="house"
                            rules={[
                                { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}House`)) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.building?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label={t(`orderMember${labelByType}Building`)}
                            name="building"
                            rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.apartment?.memberTypes?.includes(type) && (
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label={t(`orderMember${labelByType}Apartment`)}
                            name="apartment"
                            rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                )}
                {availableFormItems.passport?.memberTypes?.includes(type) && (
                    <>
                        <Col xs={24}>
                            <Typography.Title level={5}>{t('orderMemberPassport')}</Typography.Title>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                label={t('orderMemberPassportNumber')}
                                name="passport_number"
                                // rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderMemberPassportNumber')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                label={t('orderMemberPassportSerialNumber')}
                                name="passport_serial_number"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                label={t('orderMemberPassportIssuingAuthority')}
                                name="passport_issuing_authority"
                                // rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderMemberPassportIssuingAuthority')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                label={t('orderMemberPassportDate')}
                                name="passport_date"
                                // rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderMemberPassportDate')) }]}
                            >
                                <DatePicker className="w-100" format="DD.MM.YYYY" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                label={t('orderMemberPassportDivisionCode')}
                                name="passport_division_code"
                                // rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderMemberPassportDivisionCode')) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </>
                )}
                <Form.Item hidden name="type" />
                <Form.Item hidden name="order_id" />
                <Col className="text-center" xs={24}>
                    <Space className="form-action-buttons" direction="horizontal" hidden={watchMode} size="large">
                        {canMoveBetweenSteps && (
                            <Button type="link" onClick={onBackPress}>
                                {t('back')}
                            </Button>
                        )}
                        <Button type="primary" htmlType="submit" loading={saveFetch.loading || order.dataLoading}>
                            {canMoveBetweenSteps ? t('next') : t('update')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
}

export default MakeOrderMemberStep;
