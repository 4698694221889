/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react';
import {
    Button, Card, Upload, notification, Modal, Space, Input,
} from 'antd';
import type { UploadProps } from 'antd';
import { DeleteOutlined, SearchOutlined, ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { InputRef } from 'antd/lib/input';
import Table from '../../components/table';
import { useTrackingCodes } from '../../hooks/RusPost/useTrackingCodes';
import { config } from '../../constants/config';
import UserTokenService from '../../services/user-token';
import { useFetch } from '../../hooks/useFetch';
import { ITrackingCode } from '../../types/rusPost';

function RusPostCodes() {
    const { t } = useTranslation();
    const { data, loading, refresh } = useTrackingCodes();
    const deleteFetch = useFetch();
    const searchRef = useRef<InputRef|undefined>();
    const [searchParams, setSearchParams] = useSearchParams();

    const uploadProps: UploadProps = {
        name: 'file',
        action: `${config.apiUrl}/ruspost/tracking/codes/import`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${UserTokenService.getToken()}`,
        },
        onChange(info) {
            if (info.file.status === 'done') {
                notification.success({ message: t('importSucceed') });
                refresh();
            }
        },
        showUploadList: false,
    };

    const onSearchClick = () => {
        setSearchParams((prev) => {
            if (typeof searchRef.current?.input?.value === 'string' && searchRef.current?.input?.value.length > 0) {
                prev.set('search', searchRef.current?.input?.value);
            } else {
                prev.delete('search');
            }
            return prev;
        });
    };

    const extra = (
        <Space direction="horizontal">
            <Upload {...uploadProps}>
                <Button icon={<ImportOutlined />} type="primary" />
            </Upload>
            <Space>
                <Input
                    // @ts-ignore
                    ref={searchRef}
                    placeholder={t('placeholderSearch') as string}
                    defaultValue={searchParams.get('search') || undefined}
                    allowClear
                />
                <Button
                    icon={<SearchOutlined />}
                    onClick={onSearchClick}
                />
            </Space>
        </Space>
    );

    const onDeleteClick = async (id: number) => {
        Modal.confirm({
            title: t('deleteConfirmation'),
            onOk: async () => {
                await deleteFetch.request({
                    url: `/ruspost/tracking/codes/${id}/delete`,
                });
                refresh();
            },
        });
    };

    return (
        <Card extra={extra} title={t('rusPostCodes')}>
            <Table
                loading={loading}
                columns={[{
                    dataIndex: 'tracking_code',
                    title: t('trackingNumber'),
                }, {
                    dataIndex: 'order_id',
                    title: t('orderNumber'),
                    render: (value) => (value !== null ? <Link to={`/order/${value}`}>{value}</Link> : '-'),
                }, {
                    dataIndex: 'selected_at',
                    title: t('trackingNumberSelectedAt'),
                    render: (value) => (value !== null ? value : '-'),
                }, {
                    render: (_, row: ITrackingCode) => (
                        <Button
                            disabled={typeof row.order_id === 'number'}
                            loading={deleteFetch.loading}
                            icon={<DeleteOutlined />}
                            type="primary"
                            onClick={() => onDeleteClick(row.id)}
                        />
                    ),
                    width: 50,
                }]}
                dataSource={data?.list}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                }}
            />
        </Card>
    );
}

export default RusPostCodes;
