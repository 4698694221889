import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../useFetch';
import { useForm } from '../useForm';

export const useGlsCountry = () => {
    const fetch = useFetch();
    const [form] = useForm();
    const { id } = useParams();
    const navigate = useNavigate();

    const newRow = id === 'create';

    const getData = async () => {
        const result = await fetch.request({
            url: `/gls/countries/${id}`,
            method: 'get',
        });
        if (result.success && result.data) {
            form.setFieldsValue(result.data);
        }
    };

    const save = async (data: Record<string, any>) => {
        const result = await fetch.request({
            url: newRow ? '/gls/countries/create' : `/gls/countries/${id}/update`,
            method: 'post',
            data,
        });
        if (result.success && result.data) {
            navigate(`/gls/countries/price/${result.data.id}`);
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
        }
    }, [id]);

    return {
        form,
        loading: fetch.loading,
        save,
    };
};
