import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OrderContext, TOrderAvailableFormItems } from '../context/order';
import { useFetch } from './useFetch';

export const useOrderForm = (canFetch = true) => {
    const context = useContext(OrderContext);
    const fetch = useFetch();
    const { type } = useParams();

    const getData = async () => {
        if (typeof type === 'string' || typeof context.data?.id === 'number') {
            context.setAvailableFormItemsLoading(true);

            const result = await fetch.request<TOrderAvailableFormItems>({
                url: typeof context.data?.id === 'number' ? `/orders/form-items/${context.data.id}` : `/orders/form-items?type=${type}`,
            });

            if (result.success && result.data instanceof Object) {
                context.setAvailableFormItems(result.data);
            }

            context.setAvailableFormItemsLoading(false);
        }
    };

    useEffect(() => {
        if (canFetch) {
            getData();
        }
    }, [canFetch, context.data?.id, context.data?.delivery_to_company_type, context.dataLoading, type]);

    return {
        availableFormItems: context.availableFormItems,
        loading: context.availableFormItemsLoading,
    };
};
