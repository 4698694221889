import { Button, Card, Space } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useWarehousesList } from '../../hooks/useWarehousesList';
import Table from '../../components/table';

function Warehouses() {
    const { t } = useTranslation();
    const { data, deleteRow, loading } = useWarehousesList();

    const actions = (_: any, row: any) => (
        <Space>
            <Link to={`/warehouses/${row.id}`}>
                <Button
                    icon={<EditOutlined />}
                    type="primary"
                />
            </Link>
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    const extra = (
        <Link to="/warehouses/create">
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('warehouses')}>
            <Table
                loading={loading}
                columns={[
                    {
                        dataIndex: 'id',
                        title: '#',
                    },
                    {
                        dataIndex: 'company_name',
                        title: t('companyName'),
                    },
                    {
                        dataIndex: 'country',
                        title: t('country'),
                    },
                    {
                        dataIndex: 'city',
                        title: t('city'),
                    },
                    {
                        dataIndex: 'address',
                        title: t('address'),
                    },
                    {
                        dataIndex: 'postal_code',
                        title: t('postalCode'),
                    },
                    {
                        dataIndex: 'phone',
                        title: t('phone'),
                    },
                    {
                        dataIndex: 'work_mode',
                        title: t('workMode'),
                        className: 'white-space-break-spaces',
                    },
                    {
                        render: actions,
                        width: 100,
                    },
                ]}
                dataSource={data?.list}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                    pageSize: 30,
                }}
            />
        </Card>
    );
}

export default Warehouses;
