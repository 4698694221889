import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button, Card, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '../components/table';
import { useServiceCommissions } from '../hooks/useServiceCommissions';
import { IServiceCommission } from '../types/service-commission';

function ServiceCommissionsPage() {
    const { commissions, deleteCommission, loading } = useServiceCommissions();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onEditClick = (id: number) => navigate(`/service-commissions/${id}`);

    const onPlusClick = () => navigate('/service-commissions/create');

    const actions = (_: any, row: IServiceCommission) => (
        <Space>
            <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => onEditClick(row.id)}
            />
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => deleteCommission(row.id)}
            />
        </Space>
    );

    const extra = (
        <Button icon={<PlusOutlined />} type="primary" onClick={onPlusClick} />
    );

    return (
        <Card extra={extra} title={t('serviceCommissions')}>
            <Table
                loading={loading}
                columns={[{
                    dataIndex: 'weightTitle',
                    title: t('orderPackageWeight'),
                }, {
                    dataIndex: 'procent',
                    title: t('serviceCommissions'),
                    render: (_, row: IServiceCommission) => `${row.procent}%`,
                }, {
                    render: actions,
                    width: 100,
                }]}
                dataSource={commissions?.list}
                pagination={{
                    current: commissions?.currentPage,
                    total: commissions?.total,
                }}
            />
        </Card>
    );
}

export default ServiceCommissionsPage;
