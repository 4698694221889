import { useEffect } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from './useForm';
import { IFulfillment } from '../types/rusPost';
import { useFetch } from './useFetch';

interface IUseRusPostFulfillmentOptions {
    onDataLoaded?: (data: IFulfillment) => void;
}

const defaultOptions: IUseRusPostFulfillmentOptions = {};

export const useRusPostFulfillment = ({ onDataLoaded } = defaultOptions) => {
    const formFetch = useFetch();
    const saveFetch = useFetch();
    const [form] = useForm();
    const { id } = useParams();
    const navigate = useNavigate();

    const newRow = id === 'create';

    const getData = async () => {
        const result = await formFetch.request<IFulfillment>({
            url: `/ruspost/fulfillment/${id}`,
            method: 'get',
        });
        if (result.success && result.data) {
            form.setFieldsValue(result.data);
            if (typeof onDataLoaded === 'function') {
                onDataLoaded(result.data);
            }
        }
    };

    const save = async (values: IFulfillment) => {
        const result = await saveFetch.request<IFulfillment>({
            url: newRow ? '/ruspost/fulfillment/create' : `/ruspost/fulfillment/${id}/update`,
            method: 'post',
            data: values,
        });
        if (result.success) {
            notification.success({
                message: result.message,
            });
            if (newRow) {
                navigate(`/ruspost/fulfillment/${result.data?.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
        }
    }, [id]);

    return {
        loading: formFetch.loading,
        form,
        save,
        saving: saveFetch.loading,
    };
};
