import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/user';
import UserTokenService from '../services/user-token';

export const useApp = () => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const authSucceed = (token: string) => {
        UserTokenService.saveToken(token);
        navigate('/');
    };

    const logout = () => {
        UserTokenService.deleteToken();
        userContext.setInfo(undefined);
        navigate('/login');
    };

    return {
        authSucceed,
        logout,
    };
};
