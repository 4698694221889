import {
    DeleteOutlined, EditOutlined, PlusOutlined, EyeInvisibleOutlined, EyeOutlined, DollarOutlined,
} from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Table from '../../components/table';
import { useRusPostTypes } from '../../hooks/RusPost/useRusPostTypes';

function RusPostTypesPage() {
    const { deleteRow, showHide, list } = useRusPostTypes();
    const { t } = useTranslation();

    const actions = (_: any, row: any) => (
        <Space>
            <Button
                icon={row.hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                type="default"
                onClick={() => showHide(row.id)}
            />
            <Link to={`/delivery/type/${row.id}/commissions`}>
                <Button
                    icon={<DollarOutlined />}
                    type="primary"
                />
            </Link>
            <Link to={`/delivery/type/${row.id}`}>
                <Button
                    icon={<EditOutlined />}
                    type="primary"
                />
            </Link>
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    const extra = (
        <Link to="/delivery/type/create">
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('deliveryTypes')}>
            <Table
                scroll={{ x: 1200 }}
                loading={list.loading}
                dataSource={list.data?.list}
                pagination={{
                    current: list.data?.currentPage,
                    total: list.data?.total,
                }}
                columns={[{
                    dataIndex: 'name',
                    title: t('name'),
                }, {
                    dataIndex: 'delivery_company',
                    title: t('deliveryCompany'),
                }, {
                    dataIndex: 'delivery_time',
                    title: t('deliveryTime'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'code',
                    title: t('code'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'over_max_weight_price',
                    title: t('overMaxWeightPrice'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'max_length',
                    title: t('maxLength'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'over_max_length_price',
                    title: t('overMaxLengthPrice'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'max_volume',
                    title: t('maxVolume'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'over_max_volume_price',
                    title: t('overMaxVolumePrice'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'additional_price',
                    title: t('additionalPrice'),
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'hidden',
                    title: t('hidden'),
                    render: (val) => (val ? t('yes') : t('no')),
                }, {
                    dataIndex: '',
                    render: actions,
                    fixed: 'right',
                    width: 180,
                }]}
            />
        </Card>
    );
}

export default RusPostTypesPage;
