import {
    Button, Card, Col, DatePicker, Form, Input, Row,
    Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import { getRequiredFieldMessage } from '../../helpers/form';
import { usePromoCode } from '../../hooks/usePromoCode';
import { usePromoCodeTypes } from '../../hooks/usePromoCodeTypes';

function PromoCodeFormPage() {
    const {
        dataLoading, form, save, loading,
    } = usePromoCode();
    const { loading: ptLoading, types } = usePromoCodeTypes();
    const { t } = useTranslation();

    return (
        <Card title={t('promoCode')} loading={dataLoading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="code"
                            label={t('promoCode')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('promoCode')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="amount_of_discount"
                            label={t('amountOfDiscount')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('amount_of_discount')) }]}
                        >
                            <Input addonAfter="%" type="number" max={100} min={0} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} md={16} xl={12}>
                        <Form.Item
                            name="description"
                            label={t('description')}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} md={16} xl={12}>
                        <Form.Item
                            name="type"
                            label={t('promoCodeType')}
                        >
                            <Select
                                defaultValue={null}
                                options={types}
                                loading={ptLoading}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="starts_at"
                            label={t('promoCodeStartsAt')}
                        >
                            <DatePicker
                                className="w-100"
                                showTime
                                format="DD.MM.YYYY HH:mm"
                                showSecond={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="expires_at"
                            label={t('promoCodeExpiresAt')}
                        >
                            <DatePicker
                                className="w-100"
                                showTime
                                format="DD.MM.YYYY HH:mm"
                                showSecond={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SaveOutlined />}
                        loading={loading}
                    >
                        {t('save')}
                    </Button>
                </Row>
            </Form>
        </Card>
    );
}

export default PromoCodeFormPage;
