import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOrderChangeHistory } from '../hooks/useOrderChangeHistory';
import Table from '../components/table';

function OrderChangeHistoryPage() {
    const { history, loading } = useOrderChangeHistory();
    const { t } = useTranslation();

    return (
        <Card title={t('changelogs')}>
            <Table
                loading={loading}
                columns={[
                    {
                        dataIndex: 'order_id',
                        title: t('orderNumber'),
                        render: (value: number) => (
                            <Link to={`/order/${value}`}>{value}</Link>
                        ),
                    },
                    {
                        dataIndex: 'user_fio',
                        title: t('user'),
                    },
                    {
                        dataIndex: 'created_at',
                        title: t('date'),
                    },
                    {
                        className: 'white-space-break-spaces',
                        dataIndex: 'description',
                        title: t('description'),
                    },
                ]}
                dataSource={history?.list}
                pagination={{
                    current: history?.currentPage,
                    total: history?.total,
                }}
            />
        </Card>
    );
}

export default OrderChangeHistoryPage;
