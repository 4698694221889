import { useEffect, useState } from 'react';
import {
    Button, Card, Col, Form, Input, Select, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import { useRusPostFulfillment } from '../../hooks/useRusPostFulfillment';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useFetch } from '../../hooks/useFetch';
import { ISelectOption } from '../../types/general';
import { IFulfillment } from '../../types/rusPost';
import { useOrderPackageUnits } from '../../hooks/useOrderPackageUnits';

function RusPostFulfillmentForm() {
    const typesFetch = useFetch();
    const [types, setTypes] = useState<ISelectOption[]>([]);
    const [selectedType, setSelectedType] = useState<string>();
    const { t } = useTranslation();
    const pu = useOrderPackageUnits();

    const onDataLoaded = (x: IFulfillment) => {
        setSelectedType(x.type);
    };

    const {
        form, loading, save, saving,
    } = useRusPostFulfillment({ onDataLoaded });

    const getTypes = async () => {
        const result = await typesFetch.request({
            url: '/ruspost/fulfillment/types/select',
        });
        if (result.success) {
            setTypes(result.data);
        }
    };

    useEffect(() => {
        getTypes();
    }, []);

    return (
        <Card title={t('fulfillment')} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="type"
                            label={t('serviceLogsType')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('serviceLogsType')) }]}
                        >
                            <Select
                                options={types}
                                loading={typesFetch.loading}
                                onSelect={setSelectedType}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={6}>
                        <Form.Item
                            name="price"
                            label={t('price')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('price')) }]}
                        >
                            <Input type="number" suffix="€" />
                        </Form.Item>
                    </Col>
                    {selectedType === 'FULFILLMENT' && (
                        <Col xs={24} md={8} xl={6}>
                            <Form.Item
                                name="weight"
                                label={t('orderPackageWeight')}
                                rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                            >
                                <Input suffix={pu.packageUnits?.weight[0].label} />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SaveOutlined />}
                        loading={saving}
                    >
                        {t('save')}
                    </Button>
                </Row>
            </Form>
        </Card>
    );
}

export default RusPostFulfillmentForm;
