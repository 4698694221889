import {
    Button, Card, Col, Form, Row, Input, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useWarehouse } from '../../hooks/useWarehouse';
import { useCountries } from '../../hooks/useCountries';

function WarehouseForm() {
    const { countries } = useCountries();
    const { t } = useTranslation();
    const {
        form, loading, save, saveLoading,
    } = useWarehouse();

    return (
        <Card title={t('warehouses')} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('ruName')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('ruName')) }]}
                            name="name_ru"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('enName')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('enName')) }]}
                            name="name_en"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('deName')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('deName')) }]}
                            name="name_de"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('companyName')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('companyName')) }]}
                            name="company_name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('country')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('country')) }]}
                            name="country_code"
                        >
                            <Select
                                filterOption={(input, option) => (option?.label as any ?? '').includes(input)}
                                showSearch
                                options={countries.data}
                                loading={countries.loading}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('city')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('city')) }]}
                            name="city"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('street')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('street')) }]}
                            name="street"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('houseNumber')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('houseNumber')) }]}
                            name="house"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('postalCode')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('postalCode')) }]}
                            name="postal_code"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('phone')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('phone')) }]}
                            name="phone"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('ruWorkMode')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('ruWorkMode')) }]}
                            name="work_mode_ru"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('enWorkMode')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('enWorkMode')) }]}
                            name="work_mode_en"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={t('deWorkMode')}
                            required
                            rules={[{ required: true, message: getRequiredFieldMessage(t('deWorkMode')) }]}
                            name="work_mode_de"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    title={t('save') as string}
                    type="primary"
                    htmlType="submit"
                    loading={saveLoading}
                >
                    Сохранить
                </Button>
            </Form>
        </Card>
    );
}

export default WarehouseForm;
