import {
    Button, Col, Form, Input, Row, Select, Space,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useOrder } from '../../hooks/useOrder';
import { IOrder } from '../../types/order';
import { IMakeOrderStepsProps } from './first';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

function MakeOrderThirdStep({ onBackPress, goNextStep, canMoveBetweenSteps }: IMakeOrderStepsProps) {
    const saveFetch = useFetch();
    const fetchOptions = useFetch();
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const order = useOrder();
    const { t, i18n } = useTranslation();
    const [form] = useForm();
    const { getFieldMandatory, selectOpen, watchMode } = useFormWatchMode();

    const getOptions = async () => {
        const result = await fetchOptions.request<DefaultOptionType[]>({
            url: '/orders/payer-types',
        });
        if (result.success && Array.isArray(result.data)) {
            setOptions(result.data);
        }
    };

    useEffect(() => {
        getOptions();
    }, [i18n.language]);

    const onFinish = async (values: Record<string, any>) => {
        const result = await saveFetch.request<IOrder>({
            url: `/orders/${order.data?.id}/update`,
            data: values,
            method: 'post',
        });
        if (result.success) {
            await order.update(result.data?.id);
            if (canMoveBetweenSteps) {
                goNextStep();
            }
        }
    };

    return (
        <Form form={form} initialValues={order.data} layout="vertical" onFinish={onFinish}>
            <Row gutter={[30, 30]}>
                <Col xs={24} md={12}>
                    <Form.Item
                        className="ant-form-item-with-extra"
                        label={t('orderFillerEmail')}
                        name="filler_email"
                        rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderFillerEmail')) }]}
                    >
                        <Input name="filler_email" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        label={t('orderPayer')}
                        name="payer_type"
                        rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPayer')) }]}
                    >
                        <Select open={selectOpen} options={options} loading={fetchOptions.loading} />
                    </Form.Item>
                </Col>
                <Col className="text-center" xs={24}>
                    <Space className="form-action-buttons" direction="horizontal" hidden={watchMode} size="large">
                        {canMoveBetweenSteps && (
                            <Button type="link" onClick={onBackPress}>
                                {t('back')}
                            </Button>
                        )}
                        <Button type="primary" htmlType="submit" loading={saveFetch.loading || order.dataLoading}>
                            {canMoveBetweenSteps ? t('next') : t('update')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
}

export default MakeOrderThirdStep;
