import { useTranslation } from 'react-i18next';
import { Button, Card, Space } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { usePredefinedOrderMembers } from '../../hooks/usePredefinedOrderMembers';
import Table from '../../components/table';

function PredefinedOrderMembersPage() {
    const { deleteRow, response, loading } = usePredefinedOrderMembers();
    const { t } = useTranslation();

    const actions = (row: any) => (
        <Space>
            <Link to={`/predefined-order-members/${row.id}`}>
                <Button
                    icon={<EditOutlined />}
                    type="primary"
                />
            </Link>
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    const extra = (
        <Link to="/predefined-order-members/create">
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('predefinedAddresses')}>
            <Table
                loading={loading}
                columns={[{
                    dataIndex: 'id',
                    title: '№',
                    align: 'center',
                }, {
                    dataIndex: 'country',
                    title: t('country'),
                    align: 'center',
                }, {
                    dataIndex: 'city',
                    title: t('city'),
                    align: 'center',
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'street',
                    title: t('street'),
                    align: 'center',
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'phone',
                    title: t('phone'),
                    align: 'center',
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'email',
                    title: t('email'),
                    align: 'center',
                    render: (val) => val || '-',
                }, {
                    dataIndex: 'postal_code',
                    title: t('postalCode'),
                    align: 'center',
                    render: (val) => val || '-',
                }, {
                    render: actions,
                    width: 100,
                }]}
                dataSource={response?.list}
                pagination={{
                    current: response?.currentPage,
                    total: response?.total,
                }}
            />
        </Card>
    );
}

export default PredefinedOrderMembersPage;
