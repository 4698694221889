import { useEffect, useState } from 'react';
import { useFetch } from './useFetch';
import { IUserInfo } from './useUser';

export const useClient = (userId?: number) => {
    const fetch = useFetch();
    const [data, setData] = useState<IUserInfo>();

    const getData = async (id: number) => {
        const result = await fetch.request({
            url: `/clients/${id}`,
        });
        if (result.success) {
            setData(result.data);
        }
    };

    useEffect(() => {
        if (typeof userId === 'number') {
            getData(userId);
        }
    }, [userId]);

    return {
        data,
        loading: fetch.loading,
    };
};
