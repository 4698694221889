import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from './useFetch';
import { useForm } from './useForm';

export const useDeliveryTypeCommission = () => {
    const fetch = useFetch();
    const [form] = useForm();
    const { id, commissionId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<Record<string, any>>({});

    const newRow = commissionId === 'create';

    const getData = async () => {
        const result = await fetch.request({
            url: `/delivery/types/commissions/${commissionId}`,
            method: 'get',
        });
        if (result.success && result.data) {
            setFormData(result.data);
        }
    };

    const save = async (data: Record<string, any>) => {
        const result = await fetch.request({
            url: newRow ? '/delivery/types/commissions/create' : `/delivery/types/commissions/${commissionId}/update`,
            method: 'post',
            data: {
                ...data,
                delivery_type_id: id,
            },
        });
        if (result.success && result.data) {
            if (newRow) {
                navigate(`/delivery/type/${id}/commissions/${result.data.id}`, { replace: true });
            } else {
                getData();
            }
            notification.success({
                message: result.message,
            });
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
        }
    }, [id]);

    useEffect(() => {
        form.setFieldsValue(formData);
    }, [formData]);

    return {
        form,
        formData,
        loading: fetch.loading,
        save,
    };
};
