import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IListResponse } from '../../types/list';
import { ITrackingCode } from '../../types/rusPost';
import { useFetch } from '../useFetch';

export const useTrackingCodes = () => {
    const fetch = useFetch();
    const [data, setData] = useState<IListResponse<ITrackingCode>>();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');
    const search = searchParams.get('search');

    const getData = async () => {
        let url = '/ruspost/tracking/codes';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const body: Record<string, any> = {
            sorters: [{
                column: 'order_id',
                desc: true,
            }],
        };

        if (typeof search === 'string') {
            body.search = search;
        }

        const result = await fetch.request({
            url,
            method: 'post',
            data: body,
        });

        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useEffect(() => {
        getData();
    }, [page, search]);

    return {
        data,
        loading: fetch.loading,
        refresh: getData,
    };
};
