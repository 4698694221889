import { Card } from 'antd';

function SettingsPage() {
    return (
        <Card title="Настройки">
            Настройки
        </Card>
    );
}

export default SettingsPage;
