import { forwardRef, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'antd/lib/table/interface';
import { Button, Grid, Space } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Table from '../table';
import { usePurchases } from '../../hooks/usePurchases';
import { useOrder } from '../../hooks/useOrder';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

export interface IOrderPurchasesRef {
    selectedRowKeys: Key[];
    setSelectedRowKeys: (value: Key[]) => void;
}

interface IOrderPurchasesProps {
    warehouseId?: number;
}

function OrderPurchases({ warehouseId }: IOrderPurchasesProps, ref: any) {
    const order = useOrder();
    const { data, loading } = usePurchases({
        canFetch: typeof warehouseId === 'number',
        inOrderForm: true,
        orderId: order.data?.id,
        warehouseId,
    });
    const { t } = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const { watchMode } = useFormWatchMode();
    const { md } = Grid.useBreakpoint();

    useImperativeHandle(ref, () => ({
        selectedRowKeys,
        setSelectedRowKeys,
    }));

    return (
        <Space className="w-100" direction="vertical" size="large">
            {order.data instanceof Object && order.data.warehouse_id && (
                <Link to={`/purchases/create?order_id=${order.data.id}&warehouse_id=${order.data.warehouse_id}`}>
                    <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        style={md ? undefined : { height: 48, width: 48, padding: 0 }}
                    >
                        {md && <span>{t('addPurchase')}</span>}
                    </Button>
                </Link>
            )}
            <Table
                scroll={{ x: true }}
                rowKey="id"
                loading={loading}
                dataSource={data?.list}
                pagination={watchMode ? false : {
                    current: data?.currentPage,
                    total: data?.total,
                    pageSize: data?.perPage,
                }}
                columns={[{
                    dataIndex: 'name',
                    title: t('goodName'),
                }, {
                    dataIndex: 'shop_name',
                    title: t('shopName'),
                }, {
                    dataIndex: 'warehouse',
                    title: t('warehouse'),
                }, {
                    dataIndex: 'tracking_code',
                    title: t('trackingNumber'),
                }, {
                    dataIndex: 'sum',
                    title: t('sum'),
                }, {
                    render: (row) => (
                        <Link to={`/purchases/${row.id}`}>
                            <Button icon={<EditOutlined />} type="primary" />
                        </Link>
                    ),
                    width: 50,
                }]}
            />
        </Space>
    );
}

export default forwardRef(OrderPurchases);
