import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/table';
import { useCustomDuties } from '../../hooks/useCustomDuties';
import { ICustomDuty } from '../../types/custom-duty';

function CustomDuties() {
    const { response, deleteRow, loading } = useCustomDuties();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onEditClick = (id: number) => navigate(`/custom-duties/${id}`);

    const onPlusClick = () => navigate('/custom-duties/create');

    const actions = (_: any, row: ICustomDuty) => (
        <Space>
            <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => onEditClick(row.id)}
            />
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    const extra = (
        <Button icon={<PlusOutlined />} type="primary" onClick={onPlusClick} />
    );

    return (
        <Card extra={extra} title={t('customsDuties')}>
            <Table
                loading={loading}
                columns={[{
                    dataIndex: 'freeAmountTitle',
                    title: t('orderPackageCost'),
                }, {
                    dataIndex: 'procent',
                    title: t('serviceCommissions'),
                    render: (_, row: ICustomDuty) => `${row.procent}%`,
                }, {
                    render: actions,
                    width: 100,
                }]}
                dataSource={response?.list}
                pagination={{
                    current: response?.currentPage,
                    total: response?.total,
                }}
            />
        </Card>
    );
}

export default CustomDuties;
