import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IListResponse } from '../types/list';
import { ITransaction } from '../types/transaction';
import { useFetch } from './useFetch';

export const useTransactions = () => {
    const fetch = useFetch();
    const [data, setData] = useState<IListResponse<ITransaction>>();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    const getData = async () => {
        let url = '/transactions';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await fetch.request({
            url,
            method: 'post',
            data: {
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useEffect(() => {
        getData();
    }, [page]);

    return {
        data,
        loading: fetch.loading,
    };
};
