import { PercentageOutlined, SaveOutlined } from '@ant-design/icons';
import {
    Button,
    Card, Col, Form, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../helpers/form';
import { useServiceCommission } from '../hooks/useServiceCommission';

function ServiceCommissionFormPage() {
    const {
        form, loading, save, logicalOperations,
    } = useServiceCommission();
    const { t } = useTranslation();

    return (
        <Card title={t('serviceCommissions')} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            required
                            name="weight"
                            label={t('orderPackageWeight')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            required
                            name="logical_operation"
                            label={t('logicalOperation')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('logicalOperation')) }]}
                        >
                            <Select options={logicalOperations.data} loading={logicalOperations.loading} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            required
                            name="procent"
                            label={t('serviceCommissions')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('serviceCommissions')) }]}
                        >
                            <Input type="number" suffix={<PercentageOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    htmlType="submit"
                    type="primary"
                    icon={<SaveOutlined />}
                >
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default ServiceCommissionFormPage;
