import { Button, Card, Space } from 'antd';
import {
    EditOutlined, EyeOutlined, EyeInvisibleOutlined, PlusOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/table';
import { useGlsCountries } from '../../hooks/GLS/useGlsCountries';
import { IGlsPriceListItem } from '../../types/gls';

function GlsCountriesPage() {
    const { list, columns, toggleCountry } = useGlsCountries();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onEditClick = (code: string) => navigate(`/gls/countries/country/${code}`);

    const onPlusClick = () => navigate('/gls/countries/price/create');

    const actions = (_: any, row: IGlsPriceListItem) => (
        <Space direction="horizontal">
            <Button
                icon={row.hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                type="default"
                onClick={() => toggleCountry(row.country_code)}
            />
            <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => onEditClick(row.country_code)}
            />
        </Space>
    );

    const extra = (
        <Button icon={<PlusOutlined />} type="primary" onClick={onPlusClick} />
    );

    return (
        <Card extra={extra} title={`GLS ${t('prices')}`}>
            <Table
                columns={[
                    {
                        dataIndex: 'country',
                        title: t('country'),
                    },
                    ...columns.data.map((column) => ({
                        ...column,
                        render: (_: any, row: IGlsPriceListItem) => (
                            typeof column.dataIndex === 'number' && typeof row[column.dataIndex] !== 'undefined' ? row[column.dataIndex] : '-'
                        ),
                    })),
                    {
                        dataIndex: 'hidden',
                        title: t('hidden'),
                        render: (val) => (val ? t('yes') : t('no')),
                    },
                    {
                        render: actions,
                        width: 100,
                    },
                ]}
                loading={list.loading || columns.loading}
                dataSource={list.data?.list}
                pagination={{
                    current: list.data?.currentPage,
                    total: list.data?.total,
                }}
            />
        </Card>
    );
}

export default GlsCountriesPage;
