import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { IListResponse } from '../types/list';
import { IServiceCommission } from '../types/service-commission';
import { useFetch } from './useFetch';

export const useServiceCommissions = () => {
    const deleteFetch = useFetch();
    const fetch = useFetch();
    const [commissions, setCommissions] = useState<IListResponse<IServiceCommission>>();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const page = searchParams.get('page');

    const deleteCommission = (id: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/service-commissions/${id}/delete`,
                method: 'get',
            }).then(() => getCommissions()),
            okText: t('delete'),
        });
    };

    const getCommissions = async () => {
        let url = '/service-commissions';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await fetch.request({
            url,
            method: 'post',
            data: {
                sorters: [{ column: 'id', desc: true }],
            },
        });

        if (result.success) {
            setCommissions(result.data);
        }
    };

    useEffect(() => {
        getCommissions();
    }, [page]);

    return {
        commissions,
        deleteCommission,
        loading: fetch.loading,
    };
};
