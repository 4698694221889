import { notification } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ICustomDuty } from '../types/custom-duty';
import { useFetch } from './useFetch';
import { useForm } from './useForm';

export const useCustomDuty = () => {
    const [form] = useForm();
    const { id } = useParams();
    const fetch = useFetch();
    const navigate = useNavigate();

    const newRow = id === 'create';

    const getData = async () => {
        const result = await fetch.request<ICustomDuty>({
            url: `/custom-duties/${id}`,
            method: 'get',
        });
        if (result.success && result.data) {
            form.setFieldsValue(result.data);
        }
    };

    const save = async (values: ICustomDuty) => {
        const result = await fetch.request<ICustomDuty>({
            url: newRow ? '/custom-duties/create' : `/custom-duties/${id}/update`,
            method: 'post',
            data: values,
        });
        if (result.success) {
            notification.success({
                message: result.message,
            });
            if (newRow) {
                navigate(`/custom-duties/${result.data?.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
        }
    }, [id]);

    return {
        form,
        loading: fetch.loading,
        save,
    };
};
