import { notification } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IServiceCommission } from '../types/service-commission';
import { useFetch } from './useFetch';
import { useForm } from './useForm';
import { useLogicalOperations } from './useLogicalOperations';

export const useServiceCommission = () => {
    const [form] = useForm();
    const { id } = useParams();
    const fetch = useFetch();
    const navigate = useNavigate();
    const logicalOperations = useLogicalOperations();

    const newRow = id === 'create';

    const getData = async () => {
        const result = await fetch.request<IServiceCommission>({
            url: `/service-commissions/${id}`,
            method: 'get',
        });
        if (result.success && result.data) {
            form.setFieldsValue(result.data);
        }
    };

    const save = async (values: IServiceCommission) => {
        const result = await fetch.request<IServiceCommission>({
            url: newRow ? '/service-commissions/create' : `/service-commissions/${id}/update`,
            method: 'post',
            data: values,
        });
        if (result.success) {
            notification.success({
                message: result.message,
            });
            if (newRow) {
                navigate(`/service-commissions/${result.data?.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
        }
    }, [id]);

    return {
        form,
        loading: fetch.loading,
        save,
        logicalOperations,
    };
};
