import {
    DiffOutlined,
    EyeOutlined, FileDoneOutlined, QrcodeOutlined, SearchOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Space } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { useOrdersList } from '../hooks/useOrdersList';
import { IOrder } from '../types/order';
import Table from './table';
import OrderQrCodeModal, { IOrderQrCodeModalRef } from './order-qr-code-modal';
import { useOrdersListFilters } from '../hooks/useOrdersListFilters';
import SearchFilter from './Table/SearchFilter';
import { useFetch } from '../hooks/useFetch';

export interface IOrdersListProps {
    search?: string;
    userId?: number;
}

function OrdersList({ search, userId }: IOrdersListProps) {
    const notifyServicesFetch = useFetch();
    const {
        data, filters, loading, onTableChange, update, setOrderAsPaid,
    } = useOrdersList({ search, userId });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const qrCodeModal = useRef<IOrderQrCodeModalRef>();
    const listFilters = useOrdersListFilters();
    const notifyServicesOrder = useRef<IOrder>();

    const onGetQrCodeClick = (row: IOrder) => qrCodeModal.current?.show(row);

    const notifyServices = async (row: IOrder) => {
        notifyServicesOrder.current = row;
        await notifyServicesFetch.request({
            url: `/orders/${row.id}/notify-third-party-services`,
            method: 'get',
        });
        update();
    };

    const actions = (_: any, record: IOrder) => (
        <Space>
            <Button
                icon={<EyeOutlined />}
                onClick={() => navigate(`/order/${record.id}`)}
                size="small"
            />
            <Button
                disabled={!record.qr_code_available}
                type="primary"
                icon={<QrcodeOutlined />}
                title={t('getQrCode') as string}
                onClick={() => onGetQrCodeClick(record)}
                size="small"
            />
            {!record.paid && (
                <Button
                    type="primary"
                    icon={<FileDoneOutlined />}
                    title={t('makeOrderPaid') as string}
                    onClick={() => setOrderAsPaid(record)}
                    size="small"
                />
            )}
            {record.paid_data_updated && (
                <Button
                    icon={<DiffOutlined />}
                    onClick={() => notifyServices(record)}
                    size="small"
                    title={t('paidDataUpdated') as string}
                    loading={notifyServicesFetch.loading && notifyServicesOrder.current?.id === record.id}
                />
            )}
        </Space>
    );

    const dateFilter = ({
        clearFilters, selectedKeys, setSelectedKeys, confirm,
    }: FilterDropdownProps) => (
        <div className="ant-table-filter-dropdown">
            <div className="ant-dropdown-menu">
                <div className="ant-dropdown-menu-item">
                    <DatePicker.RangePicker format="DD.MM.YYYY" value={selectedKeys as any} onChange={setSelectedKeys as any} />
                </div>
            </div>
            <div className="ant-table-filter-dropdown-btns">
                <Button type="default" size="small" onClick={clearFilters}>
                    Сбросить
                </Button>
                <Button type="primary" size="small" onClick={() => confirm()}>
                    Применить
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <OrderQrCodeModal ref={qrCodeModal} />
            <Table
                loading={loading}
                scroll={{ x: 2000 }}
                dataSource={data?.list}
                columns={[
                    {
                        dataIndex: 'id',
                        title: '№',
                        align: 'center',
                        width: 100,
                        filterDropdown: SearchFilter,
                        filterIcon: <SearchOutlined />,
                    },
                    {
                        dataIndex: 'creator.name',
                        title: t('client'),
                        align: 'center',
                        filterDropdown: SearchFilter,
                        filterIcon: <SearchOutlined />,
                        render: (val, record) => (
                            <Link to={`/client/${record.creator_id}`}>
                                {val}
                            </Link>
                        ),
                    },
                    {
                        dataIndex: 'tracking',
                        title: t('trackingNumber'),
                        align: 'center',
                    },
                    {
                        dataIndex: 'glsTrackingCode.tracking_id',
                        title: `${t('trackingNumber')} (GLS)`,
                        align: 'center',
                        filterDropdown: SearchFilter,
                        filterIcon: <SearchOutlined />,
                        width: 180,
                    },
                    {
                        dataIndex: 'created_at',
                        title: t('orderCreatedDate'),
                        render: (_, row: IOrder) => moment(row.created_at).format('DD.MM.YYYY').toString(),
                        filterDropdown: dateFilter,
                        width: 140,
                        align: 'center',
                        filtered: !!filters?.find((el) => el.column === 'created_at'),
                        filteredValue: filters?.find((el) => el.column === 'created_at')?.values,
                    },
                    {
                        dataIndex: 'from_to',
                        title: t('direction'),
                        align: 'center',
                    },
                    {
                        dataIndex: 'sender.name',
                        title: t('fromWhom'),
                        align: 'center',
                        filterDropdown: SearchFilter,
                        filterIcon: <SearchOutlined />,
                    },
                    {
                        dataIndex: 'recipient.name',
                        title: t('toWhom'),
                        align: 'center',
                        filterDropdown: SearchFilter,
                        filterIcon: <SearchOutlined />,
                    },
                    {
                        dataIndex: 'status',
                        title: t('status'),
                        align: 'center',
                        render: (_, row: IOrder) => row.status.text,
                    },
                    {
                        dataIndex: 'send_datetime',
                        title: t('sendTime'),
                        align: 'center',
                    },
                    {
                        dataIndex: 'delivery_date',
                        title: t('deliveryDate'),
                        align: 'center',
                    },
                    {
                        dataIndex: 'total_weight',
                        title: t('orderPackageWeight'),
                        align: 'center',
                    },
                    {
                        dataIndex: 'type',
                        title: t('orderType'),
                        width: 200,
                        filters: listFilters?.orderTypes,
                        align: 'center',
                        filtered: !!filters?.find((el) => el.column === 'type'),
                        filteredValue: filters?.find((el) => el.column === 'type')?.values,
                    },
                    {
                        dataIndex: 'deliveryTypeName',
                        title: t('deliveryType'),
                        filters: listFilters?.deliveryTypes,
                        align: 'center',
                        filtered: !!filters?.find((el) => el.column === 'deliveryTypeName'),
                        filteredValue: filters?.find((el) => el.column === 'deliveryTypeName')?.values,
                    },
                    {
                        dataIndex: 'paid',
                        title: t('paid'),
                        render: (_, row: IOrder) => (row.paid ? t('yes') : t('no')),
                        filters: [{
                            text: t('yes'),
                            value: true,
                        }, {
                            text: t('no'),
                            value: false,
                        }],
                        align: 'center',
                        filtered: !!filters?.find((el) => el.column === 'paid'),
                        filteredValue: filters?.find((el) => el.column === 'paid')?.values,
                    },
                    {
                        title: '',
                        key: 'action',
                        render: actions,
                        fixed: 'right',
                        align: 'center',
                    },
                ]}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                    pageSize: data?.perPage,
                    showSizeChanger: false,
                }}
                onChange={onTableChange}
            />
        </>
    );
}

OrdersList.defaultProps = {
    userId: undefined,
};

export default OrdersList;
