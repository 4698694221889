import React, { useState, useMemo } from 'react';
import { IUserInfo } from '../hooks/useUser';

export const UserContext = React.createContext<{ info?: IUserInfo, setInfo: Function }>({ setInfo: () => { } });

export function UserContextWrapper({ children }: React.PropsWithChildren) {
    const [info, setInfo] = useState();

    const value = useMemo(() => ({
        info,
        setInfo,
    }), [info]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}
