import { Input, Select } from 'antd';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import {
    ChangeEventHandler, useEffect, useState,
} from 'react';
import { getPhoneCountryCode, getPhoneCountryCodes } from '../helpers/form';

function PhoneInput({
    defaultCountry, country, value, onChange,
}: any) {
    const [phone, setPhone] = useState<string>();
    const [prefix, setPrefix] = useState(
        typeof defaultCountry === 'string' || typeof country === 'string' ? `+${getCountryCallingCode((country || defaultCountry) as CountryCode)}` : '+7',
    );

    useEffect(() => {
        if (typeof phone === 'string') {
            onChange(`${prefix}${phone}`);
        }
    }, [phone, prefix]);

    useEffect(() => {
        if (typeof value === 'string') {
            const phoneCountryCode = getPhoneCountryCode(value);
            setPhone(value.replace(phoneCountryCode || prefix, ''));
            if (
                typeof country === 'undefined'
                && phoneCountryCode.length > 0
                && prefix !== phoneCountryCode
            ) {
                setPrefix(phoneCountryCode);
            }
        }
    }, [value]);

    useEffect(() => {
        if (typeof country === 'string') {
            const code = getCountryCallingCode(country as CountryCode);
            if (code) {
                setPrefix(`+${code}`);
            }
        }
    }, [country]);

    const onChangeFN: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhone(e.target.value.replace(/[^0-9]/g, ''));
    };

    return (
        <Input.Group className="phone-input" compact style={{ display: 'flex' }}>
            <Select open={typeof country === 'string' ? false : undefined} onSelect={setPrefix} value={prefix} showSearch>
                {getPhoneCountryCodes().map((code) => (
                    <Select.Option value={`+${code}`}>
                        {`+${code}`}
                    </Select.Option>
                ))}
            </Select>
            <Input
                value={phone}
                onChange={onChangeFN}
                type="numeric"
            />
        </Input.Group>
    );
}

export default PhoneInput;
