import { useEffect, useState } from 'react';
import {
    Card, Form, Input, Row, Col, Button, Select,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useFetch } from '../../hooks/useFetch';
import { useDeliveryTypeCommission } from '../../hooks/useDeliveryTypeCommission';

function DeliveryTypeCommissionForm() {
    const ptFetch = useFetch();
    const { t } = useTranslation();
    const [priceTypes, setPriceTypes] = useState<{ label: string; value: string }[]>([]);
    const { form, formData, save } = useDeliveryTypeCommission();

    const getPriceTypes = async () => {
        const result = await ptFetch.request({
            url: '/gls/transportation/price/types',
            method: 'get',
        });
        if (result.success && result.data) {
            setPriceTypes(result.data);
        }
    };

    const onSelect = (key: string, value: string | null) => form.setFieldValue(key, value);

    useEffect(() => {
        getPriceTypes();
    }, []);

    useEffect(() => {
        if (priceTypes.length > 0) {
            form.setFieldValue('commission_unit', formData.commission_unit || priceTypes[0].value);
        }
    }, [priceTypes, formData]);

    return (
        <Card title={t('deliveryTypeCommission')}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="commission"
                            label={t('commissionValue')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('commissionValue')) }]}
                        >
                            <Input
                                type="number"
                                addonAfter={(
                                    <Select
                                        key={`${formData.id}${priceTypes?.[0]?.value}commission_unit`}
                                        defaultValue={(formData.commission_unit || priceTypes?.[0]?.value)}
                                        options={priceTypes}
                                        loading={ptFetch.loading}
                                        onSelect={(value: any) => onSelect('commission_unit', value)}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item name="commission_unit" hidden />
                    </Col>
                </Row>
                <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                >
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default DeliveryTypeCommissionForm;
