import './styles/styles.less';

import AppRouter from './router';
import LanguageService from './services/language';
import AntdContextWrapper from './context/antd';
import { TablesContextWrapper } from './context/tables';

LanguageService();

function App() {
    return (
        <AntdContextWrapper>
            <TablesContextWrapper>
                <AppRouter />
            </TablesContextWrapper>
        </AntdContextWrapper>
    );
}

export default App;
