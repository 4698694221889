import { Button, Card, Space } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../components/table';
import { useGlsCountryPrices } from '../../hooks/GLS/useGlsCountryPrices';
import { IGlsPriceListItem } from '../../types/gls';

function GlsCountryPricesPage() {
    const { deleteGlsCountryPrice, list } = useGlsCountryPrices();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { code } = useParams();

    const onEditClick = (id: number) => navigate(`/gls/countries/price/${id}`);

    const onPlusClick = () => navigate(`/gls/countries/price/create?countryCode=${code}`);

    const actions = (_: any, row: IGlsPriceListItem) => (
        <Space>
            <Button icon={<EditOutlined />} type="primary" onClick={() => onEditClick(row.id)} />
            <Button icon={<DeleteOutlined />} type="primary" onClick={() => deleteGlsCountryPrice(row.id)} />
        </Space>
    );

    const extra = (
        <Button icon={<PlusOutlined />} type="primary" onClick={onPlusClick} />
    );

    return (
        <Card extra={extra} title={`GLS ${t('prices')}`}>
            <Table
                loading={list.loading}
                columns={[
                    {
                        dataIndex: 'country',
                        title: t('country'),
                    },
                    {
                        dataIndex: 'weight',
                        title: t('orderPackageWeight'),
                    },
                    {
                        dataIndex: 'price',
                        title: t('price'),
                    },
                    {
                        render: actions,
                        width: 100,
                    },
                ]}
                dataSource={list.data?.list}
                pagination={{
                    current: list.data?.currentPage,
                    total: list.data?.total,
                }}
            />
        </Card>
    );
}

export default GlsCountryPricesPage;
