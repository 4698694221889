import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../useFetch';
import { useForm } from '../useForm';

interface IRusPostTypePrice {
    id: number;
    delivery_type_id: number;
    price: number;
    weight: number;
    weight_unit: string;
}

export const useRusPostTypePrice = () => {
    const fetch = useFetch();
    const [form] = useForm();
    const { id: typeId, priceId } = useParams();
    const [data, setData] = useState<IRusPostTypePrice>();
    const navigate = useNavigate();

    const newRow = priceId === 'create';

    const getData = async () => {
        if (!newRow) {
            const result = await fetch.request({
                url: `/ruspost/prices/${priceId}`,
            });
            if (result.success && result.data) {
                setData(result.data);
            }
        }
    };

    const save = async (values: any) => {
        const result = await fetch.request<IRusPostTypePrice>({
            url: newRow ? '/ruspost/prices/create' : `/ruspost/prices/${priceId}/update`,
            method: 'post',
            data: values,
        });
        if (result.success && result.data) {
            if (newRow) {
                navigate(`/delivery/type/${typeId}/price/${result.data.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (newRow) {
            form.resetFields();
        }
        getData();
    }, [priceId]);

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    return {
        data,
        form,
        loading: fetch.loading,
        save,
        typeId,
    };
};
