/* eslint-disable no-unreachable */
import { useEffect, useRef } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useForm } from './useForm';
import { useFetch } from './useFetch';
import { IPromoCode } from '../types/promo-code';

export const usePromoCode = () => {
    const [form] = useForm();
    const { id } = useParams();
    const fetch = useFetch();
    const navigate = useNavigate();
    const dataLoaded = useRef(false);

    const newRow = id === 'create';

    const getData = async () => {
        const result = await fetch.request<IPromoCode>({
            url: `/promo-codes/${id}`,
            method: 'get',
        });
        if (result.success && result.data) {
            form.setFieldsValue({
                ...result.data,
                expires_at: result.data.expires_at ? moment(result.data.expires_at) : null,
                starts_at: result.data.starts_at ? moment(result.data.starts_at) : null,
            } as IPromoCode);
        }
        dataLoaded.current = true;
    };

    const save = async (values: IPromoCode) => {
        const result = await fetch.request<IPromoCode>({
            url: newRow ? '/promo-codes/create' : `/promo-codes/${id}/update`,
            method: 'post',
            data: {
                ...values,
                expires_at: values.expires_at ? moment(values.expires_at).format('YYYY-MM-DD HH:mm') : null,
                starts_at: values.starts_at ? moment(values.starts_at).format('YYYY-MM-DD HH:mm') : null,
            } as IPromoCode,
        });
        if (result.success) {
            notification.success({
                message: result.message,
            });
            if (newRow) {
                navigate(`/promo-codes/${result.data?.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
            dataLoaded.current = true;
        }
    }, [id]);

    return {
        dataLoading: !dataLoaded.current && fetch.loading,
        form,
        loading: fetch.loading,
        save,
    };
};
