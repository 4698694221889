import {
    Button,
    Col, Form, FormInstance, Grid, Input, Modal, Row, Space,
} from 'antd';
import {
    forwardRef, useEffect, useImperativeHandle, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useForm } from '../../hooks/useForm';
import { IOrderPackageItem } from '../../types/order';
import { useCustomsRegulations } from '../../hooks/useCustomsRegulations';

export interface OrderItemFormRef {
    show: (item?: IOrderPackageItem, index?: number) => void;
}

interface OrderItemFormProps {
    onSubmit: (values: OrderItemFormValues) => void;
    orderForm: FormInstance;
}

export interface OrderItemFormValues extends IOrderPackageItem {
    index?: number;
    newRowId?: number;
}

const { useBreakpoint } = Grid;

const OrderItemForm = forwardRef(({ onSubmit, orderForm }: OrderItemFormProps, ref) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<IOrderPackageItem>();
    const [form] = useForm();
    const { t } = useTranslation();
    const selectedIndex = useRef<number>();
    const { lg } = useBreakpoint();
    const { data, getCustomsRegulations } = useCustomsRegulations();

    const hide = () => {
        setSelected(undefined);
        setOpen(false);
    };

    const show = (item?: IOrderPackageItem, index?: number) => {
        selectedIndex.current = index;
        setSelected(item);
        setOpen(true);
    };

    const onFinish = (values: OrderItemFormValues) => {
        if (typeof values.id === 'undefined') {
            values.newRowId = new Date().valueOf();
        }
        onSubmit(values);
        hide();
    };

    useEffect(() => {
        if (open) {
            getCustomsRegulations(orderForm.getFieldValue('from'));
            form.setFieldsValue({
                ...selected,
                cost_unit: 'euro',
                index: selectedIndex.current,
            });
        } else {
            form.resetFields();
            selectedIndex.current = undefined;
        }
    }, [open, selected]);

    useImperativeHandle(ref, () => ({
        show,
    }));

    return (
        <Modal
            open={open}
            onCancel={hide}
            title={t('orderPackageInformation')}
            okText={t('save')}
            onOk={form.submit}
            footer={null}
            closable={false}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[30, 30]}>
                    <Col xs={24}>
                        <span>{t('parcelDimensionsAttentionInformation')}</span>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            extra={t('sanctionedGoodsInfo')}
                            label={t('orderPackageName')}
                            name="name"
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('orderPackageName')) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name="tncode"
                            label={t('orderPackageCode')}
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('orderPackageName')) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('orderPackageItemsCount')}
                            name="count"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemsCount')) }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            extra={data?.duty}
                            label={lg ? `${t('orderPackageCost')} (${t('itemTotalCost')})` : t('orderPackageCost')}
                            name="cost"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageCost')) }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label={t('orderPackageItemUrl')} name="url">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="text-right" xs={24}>
                        <Space className="form-action-buttons" direction="horizontal" size="large">
                            <Button onClick={hide} type="link">{t('cancel')}</Button>
                            <Button htmlType="submit" type="primary">{t('save')}</Button>
                        </Space>
                    </Col>
                </Row>
                <Form.Item hidden name="id" />
                <Form.Item hidden name="index" />
                <Form.Item hidden name="cost_unit" />
            </Form>
        </Modal>
    );
});

export default OrderItemForm;
