import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFetch } from './useFetch';
import { IListResponse } from '../types/list';
import { IPurchaseItem } from '../types/purchase';

export const usePurchaseItems = (purchaseId?: string|number) => {
    const fetchData = useFetch();
    const deleteFetch = useFetch();
    const [data, setData] = useState<IListResponse<IPurchaseItem>>();
    const { t } = useTranslation();

    const deleteRow = (x: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/purchase-items/${x}/delete`,
                method: 'get',
            }).then(() => getData()),
            okText: t('delete'),
        });
    };

    const getData = async () => {
        const result = await fetchData.request({
            url: `/purchases/${purchaseId}/items?page=1`,
            method: 'post',
            data: {
                count: 1000,
                filters: [{ column: 'purchase_id', values: [purchaseId] }],
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        data,
        deleteRow,
        getData,
        loading: fetchData.loading,
    };
};
