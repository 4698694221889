import { useSearchParams } from 'react-router-dom';

export const useFormWatchMode = () => {
    const [searchParams] = useSearchParams();
    const watchMode = searchParams.get('mode') === 'watch';

    const getFieldMandatory = (required: boolean) => (searchParams.get('mode') === 'watch' ? false : required);

    return {
        getFieldMandatory,
        selectOpen: searchParams.get('mode') !== 'watch' ? undefined : false,
        watchMode,
    };
};
