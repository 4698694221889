import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IListResponse } from '../types/list';
import { IServiceLog } from '../types/service-log';
import { useFetch } from './useFetch';

export const useServiceLogs = () => {
    const fetch = useFetch();
    const [logs, setLogs] = useState<IListResponse<IServiceLog>>();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    const getLogs = async () => {
        let url = '/service-logs';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await fetch.request({
            url,
            method: 'post',
            data: {
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success) {
            setLogs(result.data);
        }
    };

    useEffect(() => {
        getLogs();
    }, [page]);

    return {
        logs,
        loading: fetch.loading,
    };
};
