import {
    Button, Card, Col, Form, Input, Row, Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useGlsTransportationPrice } from '../../hooks/GLS/useGlsTransportationPrice';
import { useCountries } from '../../hooks/useCountries';
import { useFetch } from '../../hooks/useFetch';
import { useLogicalOperations } from '../../hooks/useLogicalOperations';
import { useOrderPackageUnits } from '../../hooks/useOrderPackageUnits';

function GlsTransportationPrice() {
    const categoriesFetch = useFetch();
    const ptFetch = useFetch();
    const { availableCountries } = useCountries();
    const {
        form, formData, loading, save,
    } = useGlsTransportationPrice();
    const { t } = useTranslation();
    const pu = useOrderPackageUnits();
    const [categories, setCategories] = useState<{ label: string; value: string }[]>([]);
    const [priceTypes, setPriceTypes] = useState<{ label: string; value: string }[]>([]);
    const logicalOperations = useLogicalOperations();

    const getCategories = async () => {
        const result = await ptFetch.request({
            url: '/gls/transportation/categories',
            method: 'get',
        });
        if (result.success && result.data) {
            setCategories(result.data);
        }
    };

    const getPriceTypes = async () => {
        const result = await ptFetch.request({
            url: '/gls/transportation/price/types',
            method: 'get',
        });
        if (result.success && result.data) {
            setPriceTypes(result.data);
        }
    };

    const onSelect = (key: string, value: string | null) => form.setFieldValue(key, value);

    const onSelectFilterOption = (input: string, option?: any) => (option?.label?.toString().toLocaleLowerCase() ?? '').includes(input.toLowerCase());

    useEffect(() => {
        getCategories();
        getPriceTypes();
    }, []);

    useEffect(() => {
        if (priceTypes.length > 0) {
            form.setFieldValue('price_type', priceTypes[0].value);
        }
    }, [priceTypes]);

    useEffect(() => {
        if (pu.packageUnits && pu.packageUnits.linear.length > 0) {
            form.setFieldValue('value_unit', pu.packageUnits.linear[0].value);
        }
    }, [pu.packageUnits]);

    const countriesOptions: { label: string; value: string | null }[] = [{
        label: t('allCountries'),
        value: null,
    }, ...(availableCountries.data as any || [])];

    return (
        <Card title={`GLS ${t('transportationPrice')}`} loading={loading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={10}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('country')}
                            name="country_code"
                            required
                        >
                            <Select
                                showSearch
                                loading={availableCountries.loading}
                                options={countriesOptions}
                                filterOption={onSelectFilterOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('category')}
                            name="category"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('category')) }]}
                        >
                            <Select
                                showSearch
                                loading={categoriesFetch.loading}
                                options={categories}
                                filterOption={onSelectFilterOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('price')}
                            name="price"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('price')) }]}
                        >
                            <Input
                                type="number"
                                addonAfter={(
                                    <Select
                                        key={`${priceTypes?.[0]?.value}price_type`}
                                        defaultValue={formData.percent === 1 ? '%' : (formData.currency || priceTypes?.[0]?.value)}
                                        options={priceTypes}
                                        loading={ptFetch.loading}
                                        onSelect={(value: any) => onSelect('price_type', value)}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item hidden name="price_type" />
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('value')}
                            name="value"
                        >
                            <Input
                                type="number"
                                addonAfter={(
                                    <Select
                                        key={`${pu.packageUnits?.linear[0].value}value_unit`}
                                        defaultValue={pu.packageUnits?.linear[0].value}
                                        options={pu.packageUnits?.linear}
                                        loading={pu.loading}
                                        onSelect={(value: any) => onSelect('value_unit', value)}
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item hidden name="value_unit" />
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="logical_operation"
                            label={t('logicalOperation')}
                        >
                            <Select options={logicalOperations.data} loading={logicalOperations.loading} />
                        </Form.Item>
                    </Col>
                </Row>
                <Button htmlType="submit" type="primary">
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default GlsTransportationPrice;
