import { Translations } from '../types/translation';
import { deTerms } from './de-terms';

export const DeLanguage: Record<Translations, string> = {
    login: 'Einloggen',
    logout: 'Ausloggen',
    profile: 'Mein Profil',
    makeOrder: 'Sendung bestellen',
    myOrders: 'Meine Bestellungen',
    delete: 'Löschen',
    name: 'Vorname',
    phone: 'Telefonnummer',
    surname: 'Nachname',
    transactions: 'Transaktionen',
    uploadPhoto: 'Photo hochladen',
    userSettings: 'Benutzereinstellungen',
    email: 'E-Mail',
    save: 'Speichern',
    transactionsEmpty: 'Transaktionenliste ist leer',
    addressSettings: 'Einstellungen der Adresse',
    apartmentNumber: 'Wohnungsnummer',
    city: 'Stadt',
    country: 'Land',
    district: 'Bezirk',
    entrance: 'Hauseingang',
    houseNumber: 'Hausnummer',
    region: 'Gebiet',
    street: 'Straße',
    cancel: 'Abbrechen',
    userDeleteContent: 'Nach dem Löschen, wird das Benutzerkonto unzugänglich',
    userDeleteTitle: 'Möchten Sie Ihr Benutzerkonto wirklich löschen?',
    applicationCompleted: 'Anmeldung abgeschlossen',
    deliveryDate: 'Zeitfenster der voraussichtlichen Lieferung',
    direction: 'Richtung',
    fromWhom: 'Von wem',
    paid: 'Bezahlt',
    sendTime: 'Zeit der Abgabe',
    shippingCompany: 'Beförderndes Unternehmen',
    status: 'Status',
    toWhom: 'An wen',
    trackingNumber: 'Verfolgungsnummer',
    editOrder: 'Bestellung redaktieren',
    payOrder: 'Bestellung bezahlen',
    payOrderTitle: 'Bezahlung von Bestellungsnummer …',
    currency: 'Währung',
    sum: 'Summe',
    pay: 'Bezahlen',
    orderFrom: 'Woher',
    orderPackageCost: 'Geldwert',
    orderPackageHeight: 'Höhe',
    orderPackageItemsCount: 'Anzahl an Gegenstände',
    orderPackageLength: 'Länge',
    orderPackageName: 'Name',
    orderPackageWeight: 'Gewicht',
    orderPackageWidth: 'Breite',
    orderPackageInformation: 'Informationen zur Sendung',
    orderTo: 'Wohin',
    back: 'Zurück',
    next: 'Weiter',
    update: 'Aktualisieren',
    checkout: 'Checkout',
    orderConfirmation: 'Bestätigung',
    orderFiller: 'Besteller',
    orderFromTo: 'Woher-Wohin',
    orderRecipient: 'Empfänger',
    orderSender: 'Versender',
    orderTransportation: 'Transport',
    orderDeliveryToCompanyTypeTitle: 'Abgabeoptionen',
    orderFillerEmail: 'E-Mail des Bestellers',
    orderPayer: 'Zahler',
    orderMemberRecipientEmail: 'E-Mail des Empfängers',
    orderMemberRecipientFullAddress: 'Volle Adresse des Empfängers',
    orderMemberRecipientName: 'Vorname des Empfängers',
    orderMemberRecipientPhone: 'Mobilrufnummer des Empfängers',
    orderMemberRecipientPostalCode: 'Postleitzahl des Empfängers',
    orderMemberRecipientSurname: 'Nachname des Empfängers',
    orderMemberRecipientMiddlename: 'Vatersname des Empfängers',
    orderMemberSenderEmail: 'E-Mail des Versenders',
    orderMemberSenderFullAddress: 'Volle Adresse des Versenders',
    orderMemberRecipientCity: 'Empfängerstadt',
    orderMemberRecipientStreet: 'Die Straße des Empfängers',
    orderMemberRecipientHouse: 'Hausnummer des Empfängers',
    orderMemberRecipientBuilding: 'Die Baunummer des Empfängers',
    orderMemberRecipientApartment: 'Die Nummer der Wohnung/Wohnung des Empfängers',
    orderMemberSenderName: 'Vorname des Versenders',
    orderMemberSenderPhone: 'Mobilrufnummer des Versenders',
    orderMemberSenderPostalCode: 'Postleitzahl des Versenders',
    orderMemberSenderSurname: 'Nachname des Versenders',
    orderMemberSenderMiddlename: 'Vatersname des Versenders',
    orderMemberSenderCity: 'Die Stadt des Absenders',
    orderMemberSenderStreet: 'Absenderstraße',
    orderMemberSenderHouse: 'Die Hausnummer des Absenders',
    orderMemberSenderBuilding: 'Nummer des Absendergebäudes',
    orderMemberSenderApartment: 'Die Nummer der Wohnung des Absenders',
    orderMemberPassport: 'Passport',
    orderMemberPassportDate: 'Datum der Ausstellung',
    orderMemberPassportDivisionCode: 'Code der zuständigen Behörde',
    orderMemberPassportIssuingAuthority: 'Ausgestellt von',
    orderMemberPassportNumber: 'Passport Nummer',
    orderMemberPassportSerialNumber: 'Passport Seriennummer',
    orderCancel: 'Abbrechen',
    orderConfirm: 'Bestellung bestätigen',
    userInfoUpdatedMessage: 'Benutzerinformationen erfolgreich aktualisiert',
    userPhotoUpdatedMessage: 'Benutzerfoto erfolgreich aktualisiert',
    no: 'Nein',
    yes: 'Ja',
    authorization: 'Anmeldung',
    authorizationByEmail: 'mit E-Mail',
    authorizationByPhone: 'mit Telefon',
    authorizationType: 'Art der Anmeldung',
    passwordForgot: 'Password vergessen',
    registration: 'Registrierung',
    password: 'Passwort',
    field: 'Feld',
    isMandatory: 'ist notwendig',
    enteredIncorrectly: 'falsch eingegeben',
    confirmPassword: 'Passwortbestätigung',
    register: 'Sich registrieren',
    passwordsDoNotMatch: 'Die eingegebenen Passwörter stimmen nicht überein ',
    registrationRejected: 'Anmeldung abgelehnt',
    registrationRejectedMessage: 'Sie haben den Nutzungsbedingungen nicht zugestimmt',
    serviceName: 'Telega',
    termsOfService: 'Geschäftsbedingungen',
    passwordRestore: 'Passwort wiederherstellen',
    passwordRestoreMessage: 'Wiederherstellungsleitfaden wurde auf Ihre E-Mail geschickt:',
    restore: 'Wiederherstellen',
    accept: 'Annehmen',
    welcome: 'Herzlich Willkommen',
    goToOrdersList: 'Zu der Bestellungsliste',
    orderPlacedSuccessfully: 'Bestellung erfolgreich gemacht',
    yourOrderNumber: 'Ihre Bestellungsnummer',
    httpRequestUnexpectedError: 'Unvorhersehbarer Fehler',
    httpRequestServeError: 'Fehler beim Laden der Daten',
    httpRequestUserNotAuthorized: 'Benutzer ist nicht angemeldet',
    httpRequestUserNotFound: 'Suchanfrage konnte nicht gefunden werden',
    termsContent: deTerms,
    panelTitle: 'Admin-Panel',
    loginField: 'Login',
    activeOrdersCount: 'Anzahl an aktive Bestellungen',
    ordersCount: 'Gesamte Anzahl an Bestellungen',
    transactionType: 'Art der Transaktion',
    date: 'Datum',
    clients: 'Kunden',
    finances: 'Finanzen',
    ordersInfo: 'Informationen zu den Bestellungen',
    serviceLogs: 'Logs',
    clientInfo: 'Informationen zum Kunde № ',
    clientOrdersList: 'Bestellungsliste des Kunden',
    clientsList: 'Kundenliste',
    transactionInfo: 'Informationen zur Transaktion №',
    financialBalance: 'Finanzielle Bilanz',
    transactionPayer: 'Zahler',
    serviceLogsType: 'Art der Operation',
    time: 'Zeit',
    user: 'Nutzer',
    exportToExcel: 'In Excel exportieren',
    orderInfo: 'Informationen zur Bestellung № ',
    fileDownloadedSuccessfully: 'File erfolgreich heruntergeladen',
    fileDownloadError: 'Fehler beim Herunterladen',
    orderCreatedDate: 'Datum der Bestellung',
    serviceCommissions: 'Service-Kommission',
    deleteConfirmation: 'Möchten Sie den ausgewählten Datensatz wirklich löschen?',
    confirmAction: 'Aktion bestätigen',
    logicalOperation: 'Logische Operation',
    prices: 'Preise',
    price: 'Preis',
    rusPostCodes: 'Spurnummern',
    deliveryType: 'Versandarten',
    deliveryTypes: 'Versandarten',
    category: 'Kategorie',
    value: 'Wert',
    deliveryTime: 'Lieferzeit',
    code: 'Kode',
    maxLength: 'Größtmaß',
    maxVolume: 'Maximale Lautstärke',
    overMaxLengthPrice: 'Der Preis ist über die maximale Größe hinaus',
    overMaxVolumePrice: 'Der Preis ist über dem maximalen Volumen',
    overMaxWeightPrice: 'Der Preis ist über dem Höchstgewicht',
    deDeliveryTime: 'Lieferzeit (Deutsch)',
    deName: 'Name (Deutsch)',
    enDeliveryTime: 'Lieferzeit (Englisch)',
    enName: 'Name (Englisch)',
    ruDeliveryTime: 'Lieferzeit (Russisch)',
    ruName: 'Name (Russisch)',
    countries: 'Länder',
    transportation: 'Transport',
    transportationPrice: 'Transportkosten',
    transportationPrices: 'Transportkosten',
    orderNumber: 'Bestellnummer',
    importSucceed: 'Der Import erfolgreich abgeschlossen',
    trackingNumberSelectedAt: 'An einen Auftrag gebunden',
    system: 'System',
    additionalPrice: 'Aufpreis',
    additionalPricePerKilogram: 'Aufpreis pro Kilogramm',
    placeholderSearch: 'Suche',
    deliveryCompany: 'Lieferservice',
    hidden: 'Versteckt',
    commissionValue: 'Provisionsbetrag',
    deliveryTypeCommission: 'Lieferart Provision',
    customsDuties: 'Zollabgaben',
    generalSettings: 'Allgemeine Einstellungen',
    cdekPhone: 'CDEK Telefon',
    orderQrInfo: 'Laden Sie bitte den QR Code herunter. Danach packen Sie sorgfältig Ihr Paket ein und bringen Sie es zu dem GLS PaketShop in Ihrer Stadt. Zeigen Sie den Code einem Mitarbeiter von GLS vor Ort, das Label wird dann gedruckt und auf das Paket geklebt.',
    download: 'Herunterladen',
    manifest: 'Manifest',
    parcelInsurance: 'Paketversicherung',
    insurancePrice: 'Versicherungskosten',
    warehouses: 'Lagerhäuser',
    address: 'Adresse',
    companyName: 'Name der Firma',
    postalCode: 'Postleitzahl',
    workMode: 'Arbeitsmodus',
    deWorkMode: 'Arbeitsmodus (Deutsch)',
    enWorkMode: 'Arbeitsmodus (Englisch)',
    ruWorkMode: 'Arbeitsmodus (Russisch)',
    orderType: 'Art der Bestellung',
    promoCodes: 'Promo-Codes',
    promoCode: 'Aktionscode',
    amountOfDiscount: 'Rabattbetrag',
    description: 'Beschreibung',
    promoCodeExpiresAt: 'Gültig bis',
    promoCodeStartsAt: 'Gültig ab',
    fulfillment: 'Erfüllung',
    shopName: 'Name des Shops',
    warehouse: 'Aktie',
    purchaseName: 'Name des Kaufs',
    predefinedAddresses: 'Vordefinierte Adressen',
    predefinedAddress: 'Predefined address',
    representative: 'Der Name des Vertreters',
    house: 'Die Hausnummer',
    building: 'Nummer des Gebäudes',
    apartment: 'Die Nummer der Wohnung',
    perItem: 'pro Stück',
    goodName: 'Produktname',
    goods: 'Produkt',
    orderPackageItemUrl: 'Link zum Produkt',
    purchases: 'Einkäufe',
    productAdding: 'Ein Produkt hinzufügen',
    goodsList: 'Produktliste',
    expressShipment: 'Express-Zustellung',
    regularShipment: 'Regelmäßige Zustellung',
    itemTotalCost: 'Gesamtpreis dieses Artikels',
    maxGirth: 'Maximaler Umfang',
    maxWeight: 'Maximales Gewicht',
    parcelDimensionsAttentionInformation: 'Bitte geben Sie Maße und Gewicht korrekt ein! Im Falle einer Abweichung zwischen den angegebenen und den tatsächlichen Daten werden die Kosten der Dienstleistung angepasst.',
    sanctionedGoodsInfo: 'Wir bitten Sie ausdrücklich, die Liste der für die Einfuhr in die Russische Föderation verbotenen Güte zu lesen',
    client: 'Klient',
    promoCodeType: 'Promo-Code-Typ',
    china: 'China',
    orderPackageCode: 'Produktcode',
    orderPackageRuName: 'Inhalt des Paketes (Russisch)',
    purchaseAdding: 'Einen Kauf hinzufügen',
    addPurchase: 'Fügen Sie einen Kauf hinzu',
    paidDataUpdated: 'Bestelldetails aktualisiert. Drittanbieterdienste benachrichtigen? (RusPost, RusPostWMS)',
    notify: 'Benachrichtigen',
    makeOrderPaid: 'Bestellung als bezahlt markieren',
    changelogs: 'Änderungsprotokolle',
};
