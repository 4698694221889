import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FinanceInfo from '../components/finance-info';
import { useTransaction } from '../hooks/useTransaction';

function FinancePage() {
    const { id } = useParams();
    const { data, loading } = useTransaction(typeof id === 'string' ? parseInt(id, 10) : undefined);
    const { t } = useTranslation();

    return (
        <Card title={`${t('transactionInfo')}${id}`} loading={loading}>
            <FinanceInfo data={data} />
        </Card>
    );
}

export default FinancePage;
