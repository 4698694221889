import { Button, Card, Space } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Table from '../../components/table';
import { useDeliveryTypeCommissions } from '../../hooks/useDeliveryTypeCommissions';

function DeliveryTypeCommissions() {
    const { deleteRow, list } = useDeliveryTypeCommissions();
    const { t } = useTranslation();
    const { id } = useParams();

    const actions = (_: any, row: any) => (
        <Space>
            <Link to={`/delivery/type/${id}/commissions/${row.id}`}>
                <Button
                    icon={<EditOutlined />}
                    type="primary"
                />
            </Link>
            <Button
                icon={<DeleteOutlined />}
                type="primary"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    const extra = (
        <Link to={`/delivery/type/${id}/commissions/create`}>
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('deliveryTypeCommission')}>
            <Table
                dataSource={list.data?.list}
                loading={list.loading}
                pagination={{
                    current: list.data?.currentPage,
                    total: list.data?.total,
                }}
                columns={[
                    {
                        dataIndex: 'commission',
                        title: t('commissionValue'),
                    },
                    {
                        render: actions,
                        width: 100,
                    },
                ]}
            />
        </Card>
    );
}

export default DeliveryTypeCommissions;
