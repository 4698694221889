import { useEffect } from 'react';
import { Button, Typography, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthorizationContent from '../components/authorization-content';
import { useUser } from '../hooks/useUser';

function WelcomePage() {
    const navigate = useNavigate();
    const { info } = useUser({ canFetch: true, canLogoutBy401: false, showFetchMessages: false });
    const { t } = useTranslation();

    useEffect(() => {
        if (info) {
            navigate('/');
        }
    }, [info]);

    const title = (
        <Typography.Title level={3}>
            {t('welcome')}
        </Typography.Title>
    );

    return (
        <AuthorizationContent title={title}>
            <Space align="center" wrap direction="horizontal" style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" onClick={() => navigate('/login')}>
                    {t('login')}
                </Button>
                <Button onClick={() => navigate('/register')}>
                    {t('register')}
                </Button>
            </Space>
        </AuthorizationContent>
    );
}

export default WelcomePage;
