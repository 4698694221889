import { FileExcelOutlined } from '@ant-design/icons';
import {
    Button, Card, Input, Space,
} from 'antd';
import FileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import OrdersList from '../components/orders-list';
import FetchService from '../services/fetch';

function OrdersPage() {
    const [params, setParams] = useSearchParams();
    const { t } = useTranslation();

    const onExportClick = async () => {
        const result = await FetchService.downloadFile({
            url: '/orders?export=excel',
            method: 'POST',
        });
        if (result.success && result.data) {
            FileDownload(result.data, 'Список заявок.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
    };

    const extra = (
        <Space>
            <Input.Search
                allowClear
                defaultValue={params.get('search') || undefined}
                placeholder="Поиск..."
                onSearch={(val) => {
                    params.set('search', val);
                    params.set('page', '1');
                    setParams(params);
                }}
            />
            <Button
                icon={<FileExcelOutlined />}
                type="primary"
                title={t('exportToExcel') as string}
                onClick={onExportClick}
            />
        </Space>
    );

    return (
        <Card title={t('ordersInfo')} extra={extra}>
            <OrdersList search={params.get('search') || undefined} />
        </Card>
    );
}

export default OrdersPage;
