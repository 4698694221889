/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { Table as ATable, TableProps } from 'antd';
import { useSearchParams } from 'react-router-dom';

function Table(props: TableProps<any>) {
    const [searchParams, setSearchParams] = useSearchParams();

    const onPageChange = (page:number) => {
        searchParams.set('page', page.toString());
        setSearchParams(searchParams);
    };

    return (
        <ATable
            {...props}
            pagination={{
                onChange: onPageChange,
                ...props.pagination,
            }}
        />
    );
}

export default Table;
