import React from 'react';
import {
    Card, Dropdown, Layout, Space, Menu,
} from 'antd';
import { useTranslation } from 'react-i18next';
import LogoPNG from '../assets/images/logo.png';
import { languagesIcons } from './header';

import '../styles/authorization.css';
import { useLanguage } from '../hooks/useLanguage';

const { Content } = Layout;

interface IAuthorizationContentProps {
    title: React.ReactNode;
}

function AuthorizationContent({ children, title }: React.PropsWithChildren<IAuthorizationContentProps>) {
    const { t, i18n } = useTranslation();
    const { changeLanguage } = useLanguage();

    const cardTitle = (
        <Space align="center" className="w-100" direction="vertical" size="large" wrap>
            <img alt={t('serviceName') as string} className="authorization-page-logo" src={LogoPNG} />
            {title}
        </Space>
    );

    const languages = (
        <Menu
            style={{ width: 170 }}
            activeKey={i18n.language}
            items={[{
                label: 'Русский',
                key: 'ru-RU',
                icon: languagesIcons['ru-RU'],
            }, {
                label: 'Deutsch',
                key: 'de-DE',
                icon: languagesIcons['de-DE'],
            }, {
                label: 'English',
                key: 'en-EN',
                icon: languagesIcons['en-EN'],
            }]}
            onClick={({ key }) => changeLanguage(key)}
        />
    );

    const languageCode:
    keyof typeof languagesIcons = Object.keys(languagesIcons).includes(i18n.language)
        ? i18n.language as keyof typeof languagesIcons : 'en-EN';

    return (
        <Layout>
            <Content className="authorization-page-content">
                <Dropdown className="authorization-page-languages-dropdown" overlay={languages} placement="bottomLeft">
                    {languagesIcons[languageCode]}
                </Dropdown>
                <Card title={cardTitle} className="authorization-card">
                    {children}
                </Card>
            </Content>
        </Layout>
    );
}

export default AuthorizationContent;
