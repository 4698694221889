import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { notification } from 'antd';
import { useForm } from './useForm';
import { useFetch } from './useFetch';
import { IPredefinedOrderMember } from '../types/predefined-order-member';

export const usePredefinedOrderMember = () => {
    const [form] = useForm();
    const { id } = useParams();
    const fetch = useFetch();
    const navigate = useNavigate();
    const dataLoaded = useRef(false);

    const newRow = id === 'create';

    const getData = async () => {
        const result = await fetch.request<IPredefinedOrderMember>({
            url: `/predefined-order-members/${id}`,
            method: 'get',
        });
        if (result.success && result.data) {
            form.setFieldsValue(result.data);
        }
        dataLoaded.current = true;
    };

    const save = async (data: IPredefinedOrderMember) => {
        const result = await fetch.request<IPredefinedOrderMember>({
            url: newRow ? '/predefined-order-members/create' : `/predefined-order-members/${id}/update`,
            method: 'post',
            data,
        });
        if (result.success) {
            notification.success({
                message: result.message,
            });
            if (newRow) {
                navigate(`/predefined-order-members/${result.data?.id}`);
            } else {
                getData();
            }
        }
    };

    useEffect(() => {
        if (!newRow) {
            getData();
        } else {
            form.resetFields();
            dataLoaded.current = true;
        }
    }, [id]);

    return {
        dataLoading: !dataLoaded.current && fetch.loading,
        form,
        loading: fetch.loading,
        save,
    };
};
