import { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useFetch } from './useFetch';

export const useDeliveryTypes = (orderId?: number) => {
    const fetch = useFetch();
    const [data, setData] = useState<DefaultOptionType[]>([]);

    const getData = async () => {
        const result = await fetch.request({
            url: `/delivery/types/select${typeof orderId === 'number' ? `?orderId=${orderId}` : ''}`,
        });
        if (result.success) {
            setData(result.data);
        }
    };

    return {
        data,
        getData,
        loading: fetch.loading,
    };
};
