import { notification } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IGlsPriceListItem } from '../../types/gls';
import { IListResponse } from '../../types/list';
import { useFetch } from '../useFetch';

export const useGlsCountries = () => {
    const dataFetch = useFetch();
    const columnsFetch = useFetch();
    const [data, setData] = useState<IListResponse<IGlsPriceListItem>>();
    const [columns, setColumns] = useState<ColumnType<any>[]>([]);
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    const getColumns = async () => {
        const result = await dataFetch.request({
            url: '/gls/countries/columns',
            method: 'get',
        });
        if (result.success && result.data) {
            setColumns(result.data);
        }
    };

    const getData = async () => {
        let url = '/gls/countries';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await dataFetch.request<IListResponse<IGlsPriceListItem>>({
            url,
            method: 'post',
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    const toggleCountry = async (countryCode: string) => {
        const result = await dataFetch.request({
            url: `/gls/countries/${countryCode}/toggle`,
            method: 'get',
        });
        if (result.success) {
            notification.success({ message: result.message });
            getData();
        }
    };

    useEffect(() => {
        getColumns();
    }, []);

    useEffect(() => {
        getData();
    }, [page]);

    return {
        columns: {
            data: columns,
            loading: columnsFetch.loading,
        },
        list: {
            data,
            loading: dataFetch.loading,
        },
        toggleCountry,
    };
};
