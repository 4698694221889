class UserTokenService {
    public static USER_TOKEN_STORAGE_KEY = 'USER_ADMIN_TOKEN';

    public static getToken = () => window.localStorage.getItem(UserTokenService.USER_TOKEN_STORAGE_KEY);

    public static saveToken = (token: string) => window.localStorage.setItem(UserTokenService.USER_TOKEN_STORAGE_KEY, token);

    public static deleteToken = () => window.localStorage.removeItem(UserTokenService.USER_TOKEN_STORAGE_KEY);
}

export default UserTokenService;
