/* eslint-disable react/jsx-props-no-spreading */
import {
    Col, Form, FormInstance, FormProps, Grid, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { getRequiredFieldMessage } from '../helpers/form';
import { usePackageUnits } from '../hooks/usePackageUnits';

const { useBreakpoint } = Grid;

function PurchaseItemForm(props: FormProps, ref: any) {
    const { lg } = useBreakpoint();
    const { t } = useTranslation();
    const packageUnits = usePackageUnits(true);

    return (
        <Form layout="vertical" ref={ref} {...props}>
            <Row gutter={[30, 30]}>
                <Col xs={24}>
                    <Form.Item
                        name="name"
                        label={t('goodName')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('goodName')) }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        name="ru_name"
                        label={t('orderPackageRuName')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageRuName')) }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="tncode"
                        label={t('orderPackageCode')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageCode')) }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="count"
                        label={t('orderPackageItemsCount')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemsCount')) }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="price"
                        label={lg ? `${t('orderPackageCost')} (${t('perItem')})` : t('orderPackageCost')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageCost')) }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageWeight')} (${t('perItem')})` : t('orderPackageWeight')}
                        name="weight"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                    >
                        <Input
                            onKeyPress={(event) => {
                                if (!/[0-9,.]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            type="number"
                            suffix={packageUnits.data?.weight[0].label}
                        />
                    </Form.Item>
                    <Form.Item hidden name="weight_unit" />
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageWidth')} (${t('perItem')})` : t('orderPackageWidth')}
                        name="width"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWidth')) }]}
                    >
                        <Input suffix={packageUnits.data?.linear[0].label} />
                    </Form.Item>
                    <Form.Item hidden name="width_unit" />
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageLength')} (${t('perItem')})` : t('orderPackageLength')}
                        name="length"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageLength')) }]}
                    >
                        <Input suffix={packageUnits.data?.linear[0].label} />
                    </Form.Item>
                    <Form.Item hidden name="length_unit" />
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageHeight')} (${t('perItem')})` : t('orderPackageHeight')}
                        name="height"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageHeight')) }]}
                    >
                        <Input suffix={packageUnits.data?.linear[0].label} />
                    </Form.Item>
                    <Form.Item hidden name="height_unit" />
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="url"
                        label={t('orderPackageItemUrl')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemUrl')) }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Form.Item name="currency" hidden />
            </Row>
        </Form>
    );
}

export default forwardRef<FormInstance, FormProps>(PurchaseItemForm);
