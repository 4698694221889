import { useEffect, useState } from 'react';
import { Divider, Menu as AMenu } from 'antd';
import {
    UserOutlined, InfoCircleOutlined, TeamOutlined, HddOutlined, DollarCircleOutlined,
    MoneyCollectOutlined, BoxPlotOutlined, CarOutlined, SettingOutlined, AppstoreOutlined,
    PercentageOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import LogoPNG from '../assets/images/logo-long.png';
import LogoNoTextPNG from '../assets/images/logo-no-text.png';

import '../styles/menu.less';
import { config } from '../constants/config';
import { USER_ROLE_ADMIN, useUser } from '../hooks/useUser';

interface IMenuProps {
    collapsed?: boolean;
}

type MenuItemType = ItemType & {
    key: string;
    children?: MenuItemType[];
};

function Menu({ collapsed }: IMenuProps) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const [selectedKey, setSelectedKey] = useState(pathname.replace('/', '') || 'orders');
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const user = useUser({ canFetch: false, canLogoutBy401: false, showFetchMessages: false });

    const menuItems: (MenuItemType & { hidden?: boolean; })[] = [{
        label: t('ordersInfo'),
        key: 'orders',
        icon: <InfoCircleOutlined />,
    }, {
        label: t('clients'),
        key: 'clients',
        icon: <TeamOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('finances'),
        key: 'finances',
        icon: <DollarCircleOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('serviceCommissions'),
        key: 'service-commissions',
        icon: <MoneyCollectOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('customsDuties'),
        key: 'custom-duties',
        icon: <MoneyCollectOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: 'GLS',
        key: 'parent-gls',
        icon: <BoxPlotOutlined />,
        children: [{
            label: t('countries'),
            key: 'gls/countries',
        }, {
            label: t('transportation'),
            key: 'gls/transportation-prices',
        }],
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: 'RusPost',
        key: 'parent-ruspost',
        icon: <BoxPlotOutlined />,
        children: [{
            label: t('rusPostCodes'),
            key: 'ruspost/codes',
        }, {
            label: t('manifest'),
            key: 'ruspost/manifest',
        }, {
            label: t('fulfillment'),
            key: 'ruspost/fulfillment',
        }],
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('china'),
        key: 'parent-china',
        icon: <BoxPlotOutlined />,
        children: [{
            label: t('manifest'),
            key: 'china/manifest',
        }],
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('deliveryTypes'),
        icon: <CarOutlined />,
        key: 'delivery/types',
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('warehouses'),
        key: 'warehouses',
        icon: <AppstoreOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('promoCodes'),
        key: 'promo-codes',
        icon: <PercentageOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('predefinedAddresses'),
        key: 'predefined-order-members',
        icon: <TeamOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('generalSettings'),
        icon: <SettingOutlined />,
        key: 'general-settings',
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('serviceLogs'),
        key: 'logs',
        icon: <HddOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('changelogs'),
        key: 'order-change-history',
        icon: <HddOutlined />,
        hidden: user.info?.role_value !== USER_ROLE_ADMIN,
    }, {
        label: t('profile'),
        key: 'profile',
        icon: <UserOutlined />,
    }];

    const onItemClick = (key: string) => {
        if (!key.includes('parent')) {
            navigate(`/${key}`);
        }
    };

    const getMenuItemsObj = (items: MenuItemType[] = [], parent: string|null = null) => {
        const menuItemsObj: Record<string, {parent: string|null}> = items.reduce((prev, curr) => ({
            ...prev,
            [curr.key]: {
                parent,
            },
            ...getMenuItemsObj(curr.children, curr.key),
        }), {});
        return menuItemsObj;
    };

    useEffect(() => {
        setSelectedKey(pathname.replace('/', '') || 'orders');
    }, [pathname]);

    useEffect(() => {
        const menuItemsObj = getMenuItemsObj(menuItems);
        if (menuItemsObj[selectedKey]) {
            const { parent } = menuItemsObj[selectedKey];
            if (!openKeys.includes(selectedKey) && typeof parent === 'string') {
                setOpenKeys((val) => {
                    val.push(parent);
                    return val;
                });
            }
        }
    }, [selectedKey]);

    return (
        <>
            <div className="menu-logo-container">
                <a href={config.siteUrl}>
                    <img alt={t('serviceName') as string} className="menu-logo" src={collapsed ? LogoNoTextPNG : LogoPNG} />
                </a>
            </div>
            <Divider />
            <AMenu
                className="left-menu"
                openKeys={openKeys}
                selectedKeys={[selectedKey]}
                items={menuItems}
                mode="inline"
                onClick={({ key }) => onItemClick(key)}
                onOpenChange={setOpenKeys}
            />
        </>
    );
}

Menu.defaultProps = {
    collapsed: false,
};

export default Menu;
