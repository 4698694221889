import React, { PropsWithChildren, useMemo, useState } from 'react';
import { IOrderPackageUnits } from '../types/order';
import { ISettings } from '../types/settings';

interface IAppContext {
    packageUnits?: IOrderPackageUnits;
    setPackageUnits: (val: IOrderPackageUnits) => void;
    settings?: ISettings;
    setSettings: (val: ISettings) => void;
}

export const AppContext = React.createContext<IAppContext>({ setPackageUnits: () => { }, setSettings: () => { } });

function AppContextWrapper({ children }: PropsWithChildren) {
    const [packageUnits, setPackageUnits] = useState<IOrderPackageUnits>();
    const [settings, setSettings] = useState<ISettings>();

    const value = useMemo(() => ({
        packageUnits,
        setPackageUnits,
        settings,
        setSettings,
    }), [packageUnits]);

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
}

export default AppContextWrapper;
